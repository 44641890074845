import {
  AppVersionAPIServices,
  GridPreferenceAPIServices,
  ManageUserAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";
import { DataGridType, getGridNameFromType } from "../types/types";

export const getPreferencesForGrid = async (
  gridType: DataGridType
): Promise<WebServiceResponse> => {
  const gridName = getGridNameFromType(gridType);
  return await performGetRequest(
    `${GridPreferenceAPIServices.getMyGridPreferences}?gridName=${gridName}`
  );
};

export const setColumnWidthForGrid = async (
  gridType: DataGridType,
  columnName: string,
  columnWidth: number
): Promise<WebServiceResponse> => {
  const columnData = {
    columnName: columnName,
    columnWidth: columnWidth,
    gridName: getGridNameFromType(gridType),
  };

  return await performPostRequest(
    GridPreferenceAPIServices.setMyGridColumnWidth,
    columnData
  );
};

export const setColumnOrderAndVisibilityForGrid = async (
  gridType: DataGridType,
  columnNames: string[]
): Promise<WebServiceResponse> => {
  const columnData = {
    columnNames: columnNames,
    gridName: getGridNameFromType(gridType),
  };
  return await performPostRequest(
    GridPreferenceAPIServices.setMyGridColumnOrderAndVisibility,
    columnData
  );
};

// COMPANY RELATED SERVICES

// Fetches the details of company by their id
export const getCompanyDetailsById = async (
  companyId: string
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.getCompanyById}/${companyId}`;
  return await performGetRequest(url);
};

// Search companies
export const searchCompanies = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = `${ManageUserAPIServices.getCompanies}/search?${parameters}`;
  return await performGetRequest(url);
};

export const getAppServiceVersion = async (): Promise<WebServiceResponse> => {
  return await performGetRequest(AppVersionAPIServices.getAppVersionInfo);
};

export const getLoginAppServiceVersion =
  async (): Promise<WebServiceResponse> => {
    return await performGetRequest(
      AppVersionAPIServices.getLoginAppVersionInfo
    );
  };