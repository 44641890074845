import { Close, Done, Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import MessageAlert from "./MessageAlert";
import PasswordInfoContent from "./PasswordInfoContent";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

const CustomPasswordInputs: React.FC<{
  passwordTitle?: string;
  confirmTitle?: string;
  error: string;
  onChangePasswordValue: (password: string, confirmPassword: string) => void;
}> = (props) => {
  const defaultCriteria = {
    hasEightChars: false,
    containsUppercase: false,
    containsLowercase: false,
    containsNumber: false,
    containsSpecialChars: false,
    satisfiesCriteria: false,
    isStrong: false,
  };

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  // Handles the password text visibility
  const [showPassword, setShowPassword] = useState<boolean>(false);
  // Handles the confirm password text visibility
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [touchedPasswordField, setTouchedPasswordField] =
    useState<boolean>(false);
  const [passwordCriteria, setPasswordCriteria] =
    useState<StrongPasswordCriteria>(defaultCriteria);
  const [showConfirmPasswordError, setShowConfirmPasswordError] =
    useState<boolean>(false);
  // Handles visibility of password text
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Handles visibility of confirm password text
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handlePasswordFieldFocus = () => setTouchedPasswordField(true);

  const showDoneMarkInPasswordField = () => {
    if (password.isNotEmpty() && confirmPassword.isEmpty()) {
      return passwordCriteria.isStrong;
    } else if (password.isNotEmpty() && confirmPassword.isNotEmpty()) {
      return passwordCriteria.isStrong && !showConfirmPasswordError;
    }
  };

  /// Handles the password entry and validates it
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value?.trim() ?? "";
    setPassword(password);
    const status = password.getStrongPasswordStatus();
    setPasswordCriteria(status);
    if (password && confirmPassword) {
      const isMatching = password === confirmPassword;
      setShowConfirmPasswordError(!isMatching);
    }
    props.onChangePasswordValue(password, confirmPassword);
  };

  /// handles the changes on the confirm field and validates it
  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPassword = event.target.value?.trim() ?? "";
    setConfirmPassword(confirmPassword);
    if (password && confirmPassword) {
      const isMatching = password === confirmPassword;
      setShowConfirmPasswordError(!isMatching);
    }
    props.onChangePasswordValue(password, confirmPassword);
  };

  return (
    <div>
      <MDBox mb={3}>
        <MDBox display={"flex"}>
          <MDTypography
            variant="button"
            fontWeight="bold"
            sx={{ fontSize: 14 }}
          >
            {props.passwordTitle ?? "Password"}
          </MDTypography>
          <RequiredFieldMarker />
        </MDBox>
        <MDInput
          type={showPassword ? "text" : "password"}
          placeholder="New Password"
          fullWidth
          onFocus={handlePasswordFieldFocus}
          onChange={handlePasswordChange}
          sx={{
            "input[type=password]": {
              "::-ms-reveal, ::-ms-clear": {
                display: "none",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <>
                  {showDoneMarkInPasswordField() && <Done color="info" />}
                  <IconButton
                    aria-label="Toggle password visibility"
                    edge="end"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      </MDBox>
      {touchedPasswordField && !passwordCriteria.isStrong && (
        <MDBox mb={2}>
          <PasswordInfoContent passwordCriteria={passwordCriteria} />
        </MDBox>
      )}
      <MDBox mb={3}>
        <MDBox display={"flex"}>
          <MDTypography
            variant="button"
            fontWeight="bold"
            sx={{ fontSize: 14 }}
          >
            {props.confirmTitle ?? "Confirm Password"}
          </MDTypography>
          <RequiredFieldMarker />
        </MDBox>
        <MDInput
          type={showConfirmPassword ? "text" : "password"}
          placeholder="Confirm New Password"
          fullWidth
          onChange={handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {password && confirmPassword && !showConfirmPasswordError && (
                  <Done color="info" />
                )}
                <IconButton
                  aria-label="Toggle password visibility"
                  edge="end"
                  onClick={handleClickShowConfirmPassword}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
        />
      </MDBox>
      {showConfirmPasswordError && (
        <MDBox mb={3}>
          <MessageAlert
            message={props.error}
            type="warning"
            icon={<Close sx={{ color: "red" }} />}
          />
        </MDBox>
      )}
    </div>
  );
};

export default CustomPasswordInputs;
