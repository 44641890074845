import { mdiMapMarkerCheck, mdiAlertCircle } from "@mdi/js";
import {
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Autocomplete,
  Switch,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MDInput from "components/MDInput";
import Icon from "@mdi/react";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import { LatLng, SiteAddressProp, autoCompleteStyle } from "@ivueit/vue-engine";
import { TEXTFIELD_CHANGE_DELAY } from "../../../../../../constants";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { CustomMapComponent } from "./googlemap/CustomMapComponent";
import { CustomMapMarker } from "./googlemap/CustomMapMarker";
import GlobalStyles from "@mui/material/GlobalStyles";
import CustomTimePicker from "pages/dashboard/components/CustomTimePicker";
import moment, { Moment } from "moment";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";
import { editVueDetails } from "../../services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { InfoOutlined } from "@mui/icons-material";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { VueReferral, VueUrgent } from "../utils/vue_detail_interface";
import { getValue } from "../helpers/helper_methods";

export interface VueDetailToBeEdited {
  id: string;
  address?: string;
  addressTwo?: string;
  notes?: string;
  startsAt?: string;
  endsAt?: string;
  cost: string;
  payout: number;
  isUrgent: number;
  canUseReferral: number;
  claimTimeLimitInMinutes: string;
}

interface Props {
  vueDetail: VueDetailToBeEdited;
  closeDialog: () => void;
  onUpdateSuccess: () => void;
}

const globalMapStyle = {
  '.gm-style > div[aria-label="Map"] > div:nth-child(2) > div': {
    top: "calc( 50% - 48px ) !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important;",
    width: "24px !important",
  },
};
const timeFormat = "hh:mm A";

const EditVueDetailsDialogContent = (props: Props) => {
  const {
    id,
    notes,
    startsAt,
    endsAt,
    address,
    addressTwo,
    cost,
    payout,
    claimTimeLimitInMinutes,
    isUrgent,
    canUseReferral,
  } = props.vueDetail;
  const [showMarker, setShowMarker] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [predictions, setPredictions] = useState<string[]>([]);
  const [coords, setCoords] = useState<LatLng>();
  const [hasMapDragged, setHasMapDragged] = useState(false);
  const [addressComponents, setAddressComponents] =
    useState<SiteAddressProp>(null);
  const [predictionSelected, setPredictionSelected] = useState<boolean>(false);
  const [error, setError] = useState({
    ERROR_MESSAGE: "",
    SITE_ADDRESS: "",
    START_TIME: "",
    END_TIME: "",
  });
  /// State variables to handle the Vue details
  const [updatedVueDetails, setUpdatedVueDetails] = useState({
    updatedAddress: address,
    updatedSuiteNo: addressTwo,
    updatedStartTime: startsAt,
    updatedEndTime: endsAt,
    updatedNotes: notes,
    updatedCost: cost,
    updatedPayout: payout,
    updatedUrgentValue: isUrgent,
    updatedCanUseReferralValue: canUseReferral,
    updatedClaimValue: claimTimeLimitInMinutes,
  });
  const {
    updatedAddress,
    updatedSuiteNo,
    updatedStartTime,
    updatedEndTime,
    updatedNotes,
    updatedCost,
    updatedPayout,
    updatedUrgentValue,
    updatedCanUseReferralValue,
    updatedClaimValue,
  } = updatedVueDetails;

  useEffect(() => {
    let errorStartTime = "";
    let errorEndTime = "";
    const startTimeMoment = moment(updatedStartTime, timeFormat);
    const endTimeMoment = moment(updatedEndTime, timeFormat);

    const validCurrentEndTime =
      updatedEndTime &&
      updatedEndTime.toLowerCase() !== "Invalid date".toLowerCase();
    const isBeforeEndTime = startTimeMoment.isSameOrBefore(endTimeMoment);
    const differenceInMinutes = endTimeMoment.diff(startTimeMoment, "minutes");
    if (startTimeMoment && !startTimeMoment.isValid()) {
      errorStartTime = "Start Time must not be empty.";
    } else if (validCurrentEndTime && !isBeforeEndTime) {
      errorStartTime = "Start Time exceeds the End Time.";
    } else if (validCurrentEndTime && differenceInMinutes < 30) {
      errorStartTime = "Minimum time window should be 30 mins";
    } else {
      errorStartTime = "";
    }

    const validCurrentStartTime =
      updatedStartTime &&
      updatedStartTime.toLowerCase() !== "Invalid date".toLowerCase();
    const isAfterStartTime = endTimeMoment.isSameOrAfter(startTimeMoment);
    if (endTimeMoment && !endTimeMoment.isValid()) {
      errorEndTime = "End Time must not be empty.";
    } else if (validCurrentStartTime && !isAfterStartTime) {
      errorEndTime = "End Time precedes the Start Time.";
    } else if (validCurrentStartTime && differenceInMinutes < 30) {
      errorEndTime = "Minimum time window should be 30 mins";
    } else {
      errorEndTime = "";
    }

    setError((prev) => ({
      ...error,
      START_TIME: errorStartTime,
      END_TIME: errorEndTime,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEndTime, updatedStartTime]);

  useEffect(() => {
    if (address) {
      setInitialCoordinates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParams = () => {
    let params: any = { id: id };
    if (updatedAddress !== address) {
      params = { ...params, address: updatedAddress };
    }
    if (updatedSuiteNo.trim() !== addressTwo.trim()) {
      params = { ...params, addressTwo: updatedSuiteNo };
    }
    if (updatedNotes.trim() !== notes.trim()) {
      params = { ...params, notes: updatedNotes };
    }
    if (updatedStartTime.trim() !== startsAt.trim()) {
      const formattedStartTime = moment(updatedStartTime, timeFormat).format(
        "HH:mm:ss"
      );
      params = {
        ...params,
        startsAt: formattedStartTime,
      };
    }
    if (updatedEndTime.trim() !== endsAt.trim()) {
      const formattedEndTime = moment(updatedEndTime, timeFormat).format(
        "HH:mm:ss"
      );
      params = { ...params, endsAt: formattedEndTime };
    }
    if (updatedCost !== cost) {
      params = { ...params, cost: parseInt(updatedCost) };
    }
    if (updatedPayout !== payout) {
      params = { ...params, payout: updatedPayout };
    }
    if (updatedClaimValue !== claimTimeLimitInMinutes) {
      params = {
        ...params,
        claimTimeLimitInMinutes: {
          value: parseInt(updatedClaimValue),
        },
      };
    }
    if (updatedUrgentValue !== isUrgent) {
      params = { ...params, urgent: updatedUrgentValue };
    }
    if (updatedCanUseReferralValue !== canUseReferral) {
      params = { ...params, canUseReferral: updatedCanUseReferralValue };
    }
    return params;
  };

  /// Handles the onClick of "Save" button
  const handleSaveClick = async () => {
    if (addressComponents) {
      setShowLoader(true);
      const params = getParams();
      const response = await editVueDetails(params);
      if (response.status === WebServiceStatus.success) {
        props.onUpdateSuccess();
        props.closeDialog();
      } else {
        setError({
          ...error,
          ERROR_MESSAGE: "Something went wrong. Vue details cannot be updated.",
        });
        console.log("Error occurred while editing Vue detail:", response.error);
      }
    } else {
      console.log(
        "Some address components are undefined:",
        console.dir(addressComponents)
      );
    }
    setShowLoader(false);
  };

  const fetchAddress = async (input: string) => {
    /// Removing space & comma from the input
    const splitInput = input
      .split(/[,\s]+/)
      .map((component) => component.trim());
    const isCoordinates =
      splitInput.length <= 2 &&
      splitInput.every((component) => !isNaN(parseFloat(component)));
    /// Executes when the input is coordinates
    if (isCoordinates) {
      if (splitInput.length === 2) {
        const [latitude, longitude] = splitInput;
        const newViewport = {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        };
        const result = await convertCoordsToPlace(
          newViewport.latitude,
          newViewport.longitude
        );
        if (!result) {
          setError({
            ...error,
            SITE_ADDRESS: "Not a valid coordinate",
          });
          setShowMarker(false);
          return;
        }
        setError({
          ...error,
          SITE_ADDRESS: "",
        });
        setShowMarker(false);
        const location = result.geometry.location;
        const addressComponents = result.address_components;
        const formattedAddress: string = result.formatted_address;
        setPredictions([formattedAddress]);
        if (predictionSelected) {
          const components = getAddressComponents(
            addressComponents,
            location,
            formattedAddress
          );
          if (!components) {
            setShowMarker(false);
            return;
          }
          setShowMarker(true);
          setCoords(newViewport);
        }
      } else {
        setError({
          ...error,
          SITE_ADDRESS: "Not a valid coordinate",
        });
        setShowMarker(false);
      }
    }
    /// Executes when the input is an address
    else {
      try {
        setError({
          ...error,
          SITE_ADDRESS: "",
        });
        /// Fetching and setting the predictions list
        const predictionsList: string[] = await getPredictionsList(input);
        if (predictionsList.length === 0) {
          setShowMarker(false);
          return;
        }
        setPredictions(predictionsList);
        /// Checking if any of the prediction is selected
        /// if yes, then fetching the details of the prediction, then setting the location
        if (predictionSelected) {
          const result = await convertPlaceToCoords(input);
          if (!result) {
            setShowMarker(false);
            return;
          }
          const location = result.geometry.location;
          const addressComponents = result.address_components;
          const formattedAddress = result.formatted_address;
          const newViewport = {
            latitude: location.lat(),
            longitude: location.lng(),
          };
          const components = getAddressComponents(
            addressComponents,
            location,
            formattedAddress
          );
          if (!components) {
            setShowMarker(false);
            return;
          }
          setShowMarker(true);
          setCoords(newViewport);
        } else {
          console.log("No prediction selected");
        }
      } catch (error) {
        setShowMarker(false);
        console.error("Error occurred while geocoding:", error);
        throw error;
      }
    }
  };

  const setInitialCoordinates = async () => {
    try {
      const result = await convertPlaceToCoords(address);
      const latitude = result.geometry.location.lat();
      const longitude = result.geometry.location.lng();
      const addressComponents = getAddressComponents(
        result.address_components,
        result.geometry.location,
        result.formatted_address
      );
      setAddressComponents(addressComponents);
      setCoords({ latitude, longitude });
      setShowMarker(true);
    } catch (err) {
      setError({
        ...error,
        SITE_ADDRESS: "Address is not valid.",
      });
      console.log(
        "Error caught while setting coords for the initial valid site address:",
        err
      );
    }
  };

  /// Fetching the predictions list - Using Autocomplete Service
  const getPredictionsList = (input: string): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const placesService = new googleMapObject.places.AutocompleteService();
        placesService.getPlacePredictions(
          { input: input },
          (predictions: any[], status: string) => {
            if (status.toUpperCase() === "OK" && predictions) {
              const listOfPredictions: string[] = predictions.map(
                (prediction) => {
                  return prediction.description;
                }
              );
              resolve(listOfPredictions);
            } else {
              resolve([]);
            }
          }
        );
      } else {
        console.error("Error occurred getPredictionsList");
      }
    });
  };

  /// Place to coordinates conversion --> Geocoding --> Using Geocode Services
  const convertPlaceToCoords = (address: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const geocoderService = new googleMapObject.Geocoder();
        geocoderService.geocode(
          { address: address },
          (results: any[], status: string) => {
            if (status.toUpperCase() === "OK" && results.length > 0) {
              const result = results[0];
              resolve(result);
            } else {
              resolve(null);
            }
          }
        );
      } else {
        console.error("Error occurred convertPlaceToCoords");
        reject(null);
      }
    });
  };

  /// Coordinates to place conversion --> Reverse Geocoding --> Using Geocode Services
  const convertCoordsToPlace = (lat: number, lng: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const geocoderService = new googleMapObject.Geocoder();
        const latLngService = new googleMapObject.LatLng(lat, lng);
        geocoderService.geocode(
          { location: latLngService },
          (results: any[], status: string) => {
            if (status.toUpperCase() === "OK" && results.length > 0) {
              const result = results[0];
              resolve(result);
            } else {
              resolve(null);
            }
          }
        );
      } else {
        console.error("Error occurred convertCoordsToPlace");
      }
    });
  };

  /// To get the main address and not the full address, to fill the "Address" column in the grid
  const getMainAddress = (mainAddressComponents: string[]) => {
    try {
      const mainAddress = mainAddressComponents
        .filter((component) => component)
        .join(", ");
      return mainAddress;
    } catch (error) {
      console.log("Error occurred while fetching the main address:", error);
    }
  };

  /// Fetching the address components - address, city, state, country, zipcode
  const getAddressComponents = (
    googleResponsesList: any,
    location: any,
    address: string
  ): SiteAddressProp => {
    let cityValue,
      stateValue,
      zipcodeValue,
      countryValue,
      errorMessage,
      streetNumberValue,
      routeValue;

    for (let item of googleResponsesList) {
      if (item.types.includes("locality")) {
        cityValue = item.long_name;
      } else if (item.types.includes("administrative_area_level_1")) {
        stateValue = item.short_name;
      } else if (item.types.includes("postal_code")) {
        zipcodeValue = item.short_name;
      } else if (item.types.includes("country")) {
        countryValue = item.short_name;
      } else if (item.types.includes("street_number")) {
        streetNumberValue = item.long_name;
      } else if (item.types.includes("route")) {
        routeValue = item.long_name;
      }
    }

    if (!cityValue) {
      errorMessage = "City is not valid.";
    } else if (!stateValue) {
      errorMessage = "State is not valid.";
    } else if (!zipcodeValue) {
      errorMessage = "Zipcode is not valid.";
    } else if (!countryValue) {
      errorMessage = "Country is not valid.";
    } else if (!streetNumberValue) {
      errorMessage = "Street number is not valid.";
    } else if (!routeValue) {
      errorMessage = "Route is not valid.";
    }
    setError({
      ...error,
      SITE_ADDRESS: errorMessage ? errorMessage : "",
    });
    setUpdatedVueDetails({
      ...updatedVueDetails,
      updatedAddress: address,
    });

    if (
      stateValue &&
      cityValue &&
      zipcodeValue &&
      countryValue &&
      streetNumberValue &&
      routeValue
    ) {
      const mainAddress = getMainAddress([streetNumberValue, routeValue]);
      const addressComponents: SiteAddressProp = {
        address: mainAddress,
        state: stateValue,
        city: cityValue,
        zipCode: zipcodeValue,
        country: countryValue,
        latLng: {
          latitude: location.lat(),
          longitude: location.lng(),
        },
      };
      setAddressComponents(addressComponents);
      return addressComponents;
    } else {
      console.log("Error occurred while parsing the address components");
      return null;
    }
  };

  const handleMapDrag = (result: any | null) => {
    if (result) {
      setHasMapDragged(true);
      getAddressComponents(
        result.address_components,
        result.geometry.location,
        result.formatted_address
      );
    }
    setShowMarker(true);
  };

  const handleInputFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const input = event.target.value;
    setUpdatedVueDetails({
      ...updatedVueDetails,
      [name]: input,
    });
  };

  const handleStartTimeChange = (newTime: Moment) => {
    setUpdatedVueDetails({
      ...updatedVueDetails,
      updatedStartTime: moment(newTime).format(timeFormat),
    });
  };

  const handleEndTimeChange = (newTime: Moment) => {
    setUpdatedVueDetails({
      ...updatedVueDetails,
      updatedEndTime: moment(newTime).format(timeFormat),
    });
  };

  const resetValues = () => {
    setShowMarker(false);
    setHasMapDragged(false);
    setPredictionSelected(false);
    setPredictions([]);
    setError({
      ...error,
      SITE_ADDRESS: "",
    });
  };

  useEffect(() => {
    if (updatedAddress && !hasMapDragged) {
      /// The method delays the api call for 0.7 ms
      /// It helps us to avoid frequent api calls & state updates, on text change
      const delayAPIInvocation = setTimeout(() => {
        fetchAddress(updatedAddress);
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delayAPIInvocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAddress]);

  return (
    <>
      {showLoader && <CustomIndicator />}
      <GlobalStyles styles={globalMapStyle} />
      <DialogContent sx={{ position: "relative" }}>
        {error.ERROR_MESSAGE && (
          <MessageAlert
            message={error.ERROR_MESSAGE}
            type="error"
            icon={<InfoOutlined />}
          />
        )}
        <MDBox display="flex" pt={4} sx={{ minWidth: "0" }}>
          <MDBox pl={2.2} pr={4} sx={{ flexBasis: "440px", flexShrink: "0" }}>
            <MDBox display={"flex"}>
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Site Address
              </MDTypography>
              <RequiredFieldMarker />
            </MDBox>
            <Autocomplete
              filterOptions={(x) => x}
              noOptionsText="No locations"
              options={predictions}
              value={updatedAddress}
              sx={autoCompleteStyle}
              onChange={(event, value) => {
                if (value) {
                  setPredictionSelected(true);
                  setUpdatedVueDetails({
                    ...updatedVueDetails,
                    updatedAddress: value,
                  });
                }
              }}
              onInputChange={(event, newInputValue) => {
                if (!newInputValue) {
                  resetValues();
                }
                setUpdatedVueDetails({
                  ...updatedVueDetails,
                  updatedAddress: newInputValue,
                });
              }}
              fullWidth
              renderInput={(params) => (
                <MDInput
                  {...params}
                  name={"address"}
                  error={error.SITE_ADDRESS}
                  placeholder="Search Address"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment:
                      updatedAddress && !error.SITE_ADDRESS && showMarker ? (
                        <Icon
                          path={mdiMapMarkerCheck}
                          size={1}
                          color="#4CAF50"
                        />
                      ) : null,
                  }}
                  fullWidth
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <LocationOnIcon sx={{ color: "text.secondary" }} />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: "calc(100% - 44px)",
                          wordWrap: "break-word",
                        }}
                      >
                        <MDTypography sx={{ fontWeight: 400, fontSize: 14 }}>
                          {option}
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
            {error.SITE_ADDRESS && (
              <MDTypography
                sx={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#AE1709",
                }}
              >
                {error.SITE_ADDRESS}
              </MDTypography>
            )}
            <MDBox
              display={"flex"}
              sx={{
                mt: "20px",
              }}
            >
              <MDTypography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Address 2
              </MDTypography>
            </MDBox>
            <MDInput
              name="updatedSuiteNo"
              value={updatedSuiteNo}
              fullWidth
              placeholder="Unit/Suite No"
              InputLabelProps={{ shrink: true }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleInputFieldChange(event, "updatedSuiteNo");
              }}
            />
            <MDBox display="flex" mt="20px">
              <MDBox flex={1} pr="8px">
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Cost
                </MDTypography>
                <MDInput
                  name="updatedCost"
                  value={updatedCost}
                  fullWidth
                  placeholder="Cost"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputFieldChange(event, "updatedCost");
                  }}
                />
              </MDBox>
              <MDBox flex={1} pl="8px">
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Payout
                </MDTypography>
                <MDInput
                  name="updatedPayout"
                  value={updatedPayout}
                  fullWidth
                  placeholder="Payout"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputFieldChange(event, "updatedPayout");
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt="20px">
              <MDBox display="flex" alignItems="center" mr={4}>
                <Switch
                  checked={getValue(updatedUrgentValue)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdatedVueDetails({
                      ...updatedVueDetails,
                      updatedUrgentValue: event.target.checked
                        ? VueUrgent.VUE_URGENT
                        : VueUrgent.VUE_NOT_URGENT,
                    });
                  }}
                />
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Urgent
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" mr={4}>
                <Switch
                  checked={getValue(updatedCanUseReferralValue)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdatedVueDetails({
                      ...updatedVueDetails,
                      updatedCanUseReferralValue: event.target.checked
                        ? VueReferral.EDIT_TO_USE_REFERRAL
                        : VueReferral.EDIT_TO_NOT_USE_REFERRAL,
                    });
                  }}
                />
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Can use referral
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                mt: "20px",
              }}
            >
              <CustomTimePicker
                title={"Start Time"}
                isRequiredField
                errorMessage={error.START_TIME}
                defaultTime={moment(updatedStartTime, timeFormat)}
                onChange={handleStartTimeChange}
                timeSteps={{ minutes: 15 }}
              />
            </MDBox>
            <MDBox
              sx={{
                mt: "20px",
              }}
            >
              <CustomTimePicker
                title={"End Time"}
                isRequiredField
                errorMessage={error.END_TIME}
                defaultTime={moment(updatedEndTime, timeFormat)}
                onChange={handleEndTimeChange}
                timeSteps={{ minutes: 15 }}
              />
            </MDBox>
            <MDBox display="flex" mt="20px">
              <MDBox flex={1}>
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Claim Time Limit in Minutes
                </MDTypography>
                <MDTypography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  (To clear this, set the value to 10080 (a week) or higher.)
                </MDTypography>
                <MDInput
                  name="updatedClaimValue"
                  value={updatedClaimValue}
                  fullWidth
                  placeholder="Claim Time Limit in Minutes"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputFieldChange(event, "updatedClaimValue");
                  }}
                />
              </MDBox>
            </MDBox>
            <MDBox
              sx={{
                mt: "20px",
              }}
            >
              <MDTypography sx={{ fontSize: "14px", fontWeight: "500" }}>
                Vue Specific Instructions
              </MDTypography>
              <MDInput
                name="updatedNotes"
                value={updatedNotes}
                fullWidth
                placeholder="Enter your text"
                multiline
                rows={7}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputFieldChange(event, "updatedNotes");
                }}
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" flexDirection="column" sx={{ flexGrow: "1" }}>
            <MDBox position="sticky" top="32px">
              <MDBox
                height="420px"
                border="1px solid #000000"
                position="relative"
              >
                <CustomMapComponent
                  showMarker={showMarker}
                  handleMapDrag={handleMapDrag}
                  coordinates={coords}
                  markerComponents={
                    showMarker && coords
                      ? [
                          <OverlayViewF
                            key={"marker"}
                            position={{
                              lat: coords.latitude,
                              lng: coords.longitude,
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                          >
                            <CustomMapMarker
                              lat={coords.latitude}
                              lng={coords.longitude}
                              color="#4CAF50"
                            />
                          </OverlayViewF>,
                        ]
                      : [<></>]
                  }
                />
              </MDBox>
              <MDBox
                display="flex"
                mt={2}
                justifyContent="center"
                alignItems="center"
                sx={{ svg: { transform: "rotate(180deg)" } }}
              >
                <Icon path={mdiAlertCircle} size={0.8} />
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    pl: "10px",
                    color: "#344767",
                  }}
                >
                  Drag to adjust location
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #a8b8d8" }}>
        <Button
          variant="outlined"
          color="info"
          size="small"
          sx={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "500",
            padding: "11px 16px",
            borderColor: "secondary.light",
            color: "dark.main",
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
            },
          }}
          onClick={props.closeDialog}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="info"
          size="small"
          disabled={
            !updatedAddress ||
            !showMarker ||
            error.SITE_ADDRESS.isNotEmpty() ||
            error.START_TIME.isNotEmpty() ||
            error.END_TIME.isNotEmpty()
          }
          sx={{
            fontSize: "14px",
            fontWeight: "500",
            cursor: "pointer",
            padding: "11px 16px",
            color: "white.main",
            backgroundColor: "success.main",
            "&:hover": { backgroundColor: "success.main" },
          }}
          onClick={handleSaveClick}
        >
          SAVE
        </Button>
      </DialogActions>
    </>
  );
};

export default EditVueDetailsDialogContent;
