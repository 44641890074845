import {
  SurveyConditions,
  SurveyStep,
  SurveyStepConditions,
  VueSurvey,
  VuerData,
} from "@ivueit/vue-engine";
import { Vue } from "../../interfaces/dashboard_interfaces";

export enum PhotoFileKeys {
  adminHide = "adminHide",
  escalated = "escalated",
  escalationNotes = "escalationNotes",
  rotation = "rotation",
  clientHide = "clientHide",
}

export enum VueHistoryType {
  editHistory = "editHistory",
  statusHistory = "statusHistory",
}

export enum VueUrgent {
  VUE_UNCHANGED = 0,
  VUE_URGENT = 1,
  VUE_NOT_URGENT = 2,
}

export enum VueReferral {
  UNEDITED_REFERRAL = 0,
  EDIT_TO_USE_REFERRAL = 1,
  EDIT_TO_NOT_USE_REFERRAL = 2,
}

/// REQUEST PARAMS FOR UPDATE PROFILE
export interface EditPhotoInfo {
  [PhotoFileKeys.adminHide]?: boolean;
  [PhotoFileKeys.escalated]?: boolean;
  [PhotoFileKeys.escalationNotes]?: string;
  [PhotoFileKeys.rotation]?: string;
  [PhotoFileKeys.clientHide]?: string;
}

export interface IndividualVue {
  vue: Vue;
  submissionId: string;
  status: string;
  vuerCompletedAt: string;
  companyId: string;
  companyName: string;
  lastUpdatedAt: string;
  photos?: PhotoFileInfo[];
  videos?: VideoFileInfo[];
  rejectionNotes: string;
  pending: boolean;
  pendingNotes: string;
  authorId: string;
  publishAt: string;
  claimTimeLimitInMinutes?: {
    value: string;
  };
  payout: number;
  internalEntityId: string;
}

export const individualVueState: IndividualVue = {
  vue: {
    id: "",
    canonicalId: "",
    title: "",
    description: "",
    notes: "",
    latitude: 0,
    longitude: 0,
    typeOf: "",
    slaTarget: "",
    tz: "",
    createdAt: "",
    vueTags: [],
    mediaTags: [],
    escalated: false,
    escalationNotes: "",
    slaActual: "",
    adminWroteEscalationNotes: false,
    streetAddress: "",
    city: "",
    stateProvinceAbbreviation: "",
    zipPostalCode: "",
    zipSuffixCode: "",
    countryAbbreviation: "",
    pdfFileId: "",
    pdfFileIdSurvey: "",
    pdfFileIdPhotos: "",
    pdfFileIdOrdered: "",
    cost: "",
    isInternal: false,
    surveyName: "",
    surveyTemplateId: "",
    selectedDays: '{"selectedDays": []}',
    startsAt: "",
    endsAt: "",
    startsAtDst: "",
    endsAtDst: "",
    urgent: false,
    highQualityRequired: false,
    siteName: "",
    siteNumber: "",
    siteNameNumber: "",
    batchID: "",
    batchName: "",
    backgroundCheckRequired: false,
    arRequired: false,
    includePhotoTimestamp: false,
    canUseReferral: false,
    jobSiteId: "",
    mapFileId: "",
    fileAttachmentIDs: [],
    score: 0,
    canonicalBatchId: "",
  },
  submissionId: "",
  status: "",
  vuerCompletedAt: "",
  companyId: "",
  companyName: "",
  lastUpdatedAt: "",
  photos: [],
  videos: [],
  rejectionNotes: "",
  pending: false,
  pendingNotes: "",
  authorId: "",
  publishAt: "",
  payout: 0,
  internalEntityId: "",
};

/// VUE SURVEY

export interface VueSurveyData {
  survey: VueSurvey;
  groups: SurveyGroupWithStep[];
  conditions?: SurveyConditions[];
  stepConditions?: SurveyStepConditions[];
}

export interface SurveyGroups {
  id: string;
  groupTitle: string;
  minInstances: number;
  maxInstances: number;
}

export interface SurveyGroupWithStep extends SurveyGroups {
  steps: SurveyStep[];
}

export interface SurveyStepState {
  id: string;
  stepId: string;
  submissionId: string;
  value: string;
  updatedFromLocation: { latitude: number; longitude: number };
  lastUpdated: string;
  escalated: boolean;
  escalationNotes: string;
  groupInstanceIndex: number;
  visible: boolean;
}

export interface PhotoFileInfo {
  id: string;
  canonicalId: string;
  filename: string;
  mimeType: string;
  data?: string;
  uploadedAt: string;
  fileSize: string;
  capturedAt: string;
  escalated: boolean;
  adminEscalated: boolean;
  escalationNotes: string;
  userFlagged: boolean;
  userNotes: string;
  rotation: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  adminHide: boolean;
  serialNumber: string;
  clientHide: boolean;
  appliedMediaTags: string[];
  photoTagNames?: string[];
  stepStateId?: string;
}

export interface VideoFileInfo {
  id: string;
  canonicalId: string;
  filename: string;
  mimeType: string;
  fileSize: string;
  durationInMilliseconds: string;
  thumbnailFileId: string;
  videoHeight: number;
  videoWidth: number;
  capturedAt: string;
  escalated: boolean;
  adminEscalated: boolean;
  escalationNotes: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  adminHide: boolean;
  clientHide: boolean;
  uploadedAt: string;
  serialNumber: string;
  appliedMediaTags: any[];
  stepStateId?: string;
}

export interface VueHistory {
  vues: EditHistory[];
  submissions: StatusHistory[];
}

export interface EditHistory {
  historyId: string;
  id: string;
  notes: {
    value: string;
  };
  postalAddress: {
    value: string;
  };
  payout: number;
  cost: number;
  submissionId: string;
  startsAt: {
    value: string;
  };
  endsAt: {
    value: string;
  };
  startsAtDst: {
    value: string;
  };
  endsAtDst: {
    value: string;
  };
  tz: {
    value: string;
  };
  meta: { [key: string]: string };
  escalated: boolean;
  claimTimeLimitInMinutes?: any;
  modifiedBy: string;
  modifiedAt: string;
  operation: string;
  urgent: boolean;
  canUseReferral: boolean;
}

export interface StatusHistory {
  historyId: string;
  id: string;
  vuerId: string;
  vueId: string;
  vuerCanonicalId: {
    value: string;
  };
  vuerName: {
    value: string;
  };
  status: string;
  submissionCreatedAt: string;
  adminRejectionNotes: string;
  autofailAt: {
    value: string;
  };
  active: boolean;
  vuerCompletedAt?: {
    value: string;
  };
  modifiedBy: string;
  modifiedAt: string;
  operation: string;
  urgent: boolean;
  pending: boolean;
  pendingNotes: string;
  referralCode: string;
}

export interface EditHistoryGridData {
  id: number;
  address: string;
  cost: string;
  payout: string;
  startsAt: string;
  endsAt: string;
  claimLimit: string;
  notes: string;
  open: string;
  urgent: string;
  canUseReferral: string;
  editedAt: string;
  editedBy: string;
}

export interface StatusHistoryGridData {
  id: number;
  vuerID: string;
  vuer: string;
  status: string;
  notes: string;
  pending: boolean;
  pendingNotes: string;
  referralCode: string;
  updatedAt: string;
  updatedBy: string;
}

export interface VueSubmission {
  id: string;
  userId: string;
  vueId: string;
  surveyTemplateId: string;
  status: string;
  createdAt: string;
  lastUpdatedAt: string;
  adminRejectionNotes: string;
  autofailAt: string;
  vuerCompletedAt: string;
  vueCompletedAt: string;
  pending: boolean;
  pendingNotes: string;
  referralCode: string;
  pendingCancellation: boolean;
}

export interface VueSubmissionUser extends VuerData {
  mostRecentTosAcceptedAt: {
    value: string;
  };
  requiresTosAcceptanceRenewal: boolean;
}
