import { DialogContent, DialogActions } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import { useState } from "react";
import { createManualTransaction } from "../services/VuerServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { TYPE_FOR_CREATING_TRANSACTION } from "../utils/constants";
import { VuerData } from "@ivueit/vue-engine";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  vuer: VuerData;
  closeDialog: () => void;
  onSuccess: () => void;
  onSucessAdjustment: () => void;
}

const AdjustDialogContent = (props: Props) => {
  const { id } = props.vuer;
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState<{
    description: string;
    failedVueIgnoredCount: string;
  }>({
    description: "",
    failedVueIgnoredCount: "",
  });
  const [amount, setAmount] = useState<string>("0");
  const [description, setDescription] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const createAdjustment = async () => {
    const type = TYPE_FOR_CREATING_TRANSACTION;
    const params = {
      amount: amount,
      notes: notes,
      shortDescription: description,
      typeOf: type,
    };
    setError({
      description: "",
      failedVueIgnoredCount: "",
    });
    setShowLoader(true);
    const response = await createManualTransaction(id, params);
    if (response.status === WebServiceStatus.success) {
      props.onSucessAdjustment();
      setSnackBar({
        title: "Success!",
        message: "Transaction created successfully",
        isError: false,
      });
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const descriptionError =
      value && value.length > 14
        ? "Description cannot exceed 14 characters"
        : "";
    setDescription(value);
    setError({ ...error, description: descriptionError });
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DialogContent>
        <MDBox flex={1} pt={2} mb={1.4}>
          <MDTypography sx={regularTextStyle}>
            Adjust <RequiredFieldMarker />
          </MDTypography>
          <MDInput
            placeholder="Enter adjust amount"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAmount(event.target.value);
            }}
            fullWidth
          />
        </MDBox>
        <MDBox flex={1} mb={1.4}>
          <MDTypography sx={regularTextStyle}>
            Short Description <RequiredFieldMarker />
          </MDTypography>
          <MDInput
            error={error.description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeDescription(event);
            }}
            placeholder="Enter short description"
            fullWidth
            multiline
            rows="2"
          />
          {error.description && (
            <MDTypography
              sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
            >
              {error.description}
            </MDTypography>
          )}
        </MDBox>
        <MDBox flex={1}>
          <MDTypography sx={regularTextStyle}>
            Notes <RequiredFieldMarker />
          </MDTypography>
          <MDInput
            placeholder="Enter notes"
            fullWidth
            multiline
            rows="3"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNotes(event.target.value);
            }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            onClick={createAdjustment}
            disabled={
              !amount.trim() ||
              !notes.trim() ||
              (description && description.length > 14)
            }
          >
            ADJUST
          </MDButton>
        </>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default AdjustDialogContent;
