import {
  WebServiceStatus,
  searchBarStyle,
  SurveyStep,
} from "@ivueit/vue-engine";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CustomSingleSelect } from "pages/dashboard/components/CustomSingleSelect";
import { MEDIA_ORIENTATION_TYPES } from "../utils/constants";
import { HeaderStepSection } from "./HeaderStepSection";
import {
  helperTextStyle,
  inputNumberStyle,
  regularTextStyle,
} from "../styles/SurveyStyles";
import { StepActionType, getDeviceOrientationValue } from "../types/enums";
import { CustomPreviewComponent } from "./CustomPreviewComponent";
import MDInput from "components/MDInput";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useCallback, useEffect, useState } from "react";
import { updateSurveyTemplateStep } from "../services/SurveyServices";
import { TEXTFIELD_CHANGE_DELAY } from "../../../constants";
import {
  SurveyGroupWithStep,
  VueSurveyData,
} from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import {
  hasConditionsOnSurvey,
  hasConditionsOnSurveyStep,
  isGroupOrphaned,
} from "../utils/helpers";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  onHeaderClick: (type: StepActionType) => void;
  stepNumber: string;
  hasBeenGrouped: boolean;
  hasConditionsApplied: boolean;
  showAddConditionButton: boolean;
  step: SurveyStep;
  groupName: string;
  onSuccess: (step: SurveyStep) => void;
  surveyData: VueSurveyData;
  onDeleteGroup: (id: string) => void;
  groupIndex: number;
}

export const DraftModeVideoComponent = ({
  onHeaderClick,
  stepNumber,
  hasBeenGrouped,
  hasConditionsApplied,
  showAddConditionButton,
  step,
  groupName,
  onSuccess,
  onDeleteGroup,
  surveyData,
  groupIndex,
}: Props) => {
  const [videoStep, setVideoStep] = useState<SurveyStep>(step);
  const [delayTypeAction, setDelayTypeAction] = useState<boolean>(false);
  // Currently unused
  const [showLoader, setShowLoader] = useState(false);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const {
    id,
    description,
    videoSecondsMin,
    videoSecondsMax,
    deviceOrientation,
  } = videoStep;
  const [error, setError] = useState({
    prompt: "",
    minValue: "",
    maxValue: "",
  });

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setVideoStep({ ...videoStep, [event.target.name]: value });
    setError({ ...error, prompt: !value ? "Prompt cannot be empty" : "" });
    setDelayTypeAction(value.isNotEmpty());
  };

  const handleMinValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const min = parseInt(value);
    setVideoStep({ ...videoStep, [event.target.name]: min });
    const isValid =
      !isNaN(min) &&
      !isNaN(videoSecondsMax) &&
      min >= 0 &&
      min <= videoSecondsMax;
    setError({
      ...error,
      minValue: !isValid ? "Invalid min value" : "",
      maxValue: "",
    });
    setDelayTypeAction(isValid);
  };

  const handleMaxValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const max = parseInt(value);
    const isValid =
      !isNaN(videoSecondsMin) &&
      !isNaN(max) &&
      max >= 0 &&
      videoSecondsMin <= max;
    setVideoStep({ ...videoStep, [event.target.name]: max });
    setError({
      ...error,
      maxValue: !isValid ? "Invalid max value" : "",
      minValue: "",
    });
    setDelayTypeAction(isValid);
  };

  const updateGroupIdForStep = () => {
    // Updating the groupId for this step
    setVideoStep({ ...videoStep, groupId: "" });
    setDelayTypeAction(true);
  };

  const deleteGroup = () => {
    const hasSurveyConditions = hasConditionsOnSurvey(surveyData, step);
    const hasStepConditions = hasConditionsOnSurveyStep(surveyData, step);
    // TODO - Allow/deny based on specific scenaario, for now deny all
    // when ungrouping, stepcondition could contain condition from step in group, if so deny
    // always deny if parent step of condition, as only other steps of same group could be linked
    if (hasSurveyConditions || hasStepConditions) {
      setSnackBar({
        title: "Attention!",
        message: "This step is linked to another, cannot ungroup step.",
        isError: true,
      });
      return;
    }
    const islastStepInTheGroup = isGroupOrphaned(step, surveyData.survey.steps);
    const groupID = islastStepInTheGroup ? videoStep.groupId : "";
    if (groupID) {
      // Removing the group if the group is orphaned
      onDeleteGroup(groupID);
    }
    updateGroupIdForStep();
  };

  const handleUpdateSurveyStep = useCallback(async () => {
    if (id.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const orientation = getDeviceOrientationValue(videoStep.deviceOrientation);
    const requestBody = { ...videoStep, deviceOrientation: orientation };
    const response = await updateSurveyTemplateStep(id, requestBody);
    if (response.status === WebServiceStatus.success) {
      const data = response.data as SurveyStep;
      onSuccess(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: "Unable to update Video step.",
        isError: true,
      });
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoStep, id]);

  useEffect(() => {
    if (delayTypeAction) {
      const delaySearchAction = setTimeout(() => {
        handleUpdateSurveyStep();
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
  }, [handleUpdateSurveyStep, delayTypeAction]);

  return (
    <MDBox p="20px">
      <HeaderStepSection
        stepNumber={stepNumber}
        groupName={groupName}
        stepType={"Video"}
        isGrouped={hasBeenGrouped}
        onButtonClick={(type: StepActionType) => {
          type === StepActionType.ungroup ? deleteGroup() : onHeaderClick(type);
        }}
        hasConditionApplied={hasConditionsApplied}
        showAddConditionButton={showAddConditionButton}
        groupIndex={groupIndex}
      />
      <MDBox
        border="1px solid #C7CCD0"
        p="16px 16px 20px 16px"
        borderRadius="8px"
      >
        <MDBox mb={1}>
        <MDTypography sx={regularTextStyle}>
            Prompt
            <RequiredFieldMarker />
          </MDTypography>
          <MDInput
            fullWidth
            name="description"
            value={description}
            error={error.prompt}
            placeholder="Enter Prompt"
            multiline
            rows={2}
            onChange={handlePromptChange}
          />
        </MDBox>
        <MDBox display="flex" mb={1}>
          <MDBox>
            <MDTypography sx={regularTextStyle}>Minimum Length<RequiredFieldMarker />
            </MDTypography>
            <MDBox position="relative" sx={helperTextStyle}>
              <MDInput
                name="videoSecondsMin"
                placeholder="Min"
                type="number"
                error={error.minValue}
                helperText={error.minValue}
                value={videoSecondsMin}
                sx={{
                  ...searchBarStyle,
                  ...inputNumberStyle,
                }}
                onChange={handleMinValueChange}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
          </MDBox>
          <MDBox ml={1.2}>
            <MDTypography sx={regularTextStyle}>
              Maximum Length (0 for unlimited)<RequiredFieldMarker />
            </MDTypography>
            <MDBox position="relative" sx={helperTextStyle}>
              <MDInput
                name="videoSecondsMax"
                placeholder="Max"
                type="number"
                error={error.maxValue}
                helperText={error.maxValue}
                value={videoSecondsMax}
                sx={{
                  ...searchBarStyle,
                  ...inputNumberStyle,
                }}
                onChange={handleMaxValueChange}
                onWheel={(event: React.ChangeEvent<HTMLInputElement>) =>
                  event.target.blur()
                }
              />
            </MDBox>
          </MDBox>
          <MDBox
            ml={1.2}
            width={"120px"}
            sx={{
              ".MuiInputBase-formControl": {
                marginTop: "0 !important",
              },
              ".MuiFormControl-root": {
                width: "100%",
              },
            }}
          >
            <MDTypography sx={regularTextStyle}>Video Orientation<RequiredFieldMarker />
            </MDTypography>
            <CustomSingleSelect
              optionsList={MEDIA_ORIENTATION_TYPES}
              value={deviceOrientation as string}
              onChange={(option: string) => {
                setDelayTypeAction(true);
                setVideoStep({ ...videoStep, deviceOrientation: option });
              }}
            />
          </MDBox>
        </MDBox>
        <MDBox mb={1}>
          <CustomPreviewComponent step={videoStep} />
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </MDBox>
  );
};
