import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import MDInput from "components/MDInput";
import { useRef, useState } from "react";
import UserImageAvatar from "pages/vuers/components/UserImageAvatar";
import { InfoOutlined } from "@mui/icons-material";
import imageCompression from "browser-image-compression";
import MessageAlert from "pages/authentication/components/MessageAlert";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  name: string;
  fileId: string;
  closeDialog: () => void;
  handleFileUpload: (file: File) => void;
  onSuccess: (updatedValue: { [key: string]: string }) => void;
}

const EditClientProfileDialogContent = (props: Props) => {
  const hiddenFileInputRef = useRef(null);
  const [name, setName] = useState<string>(props.name);
  const [error, setError] = useState<string>("");
  const [messageAlertError, setMessageAlertError] = useState<string>("");

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pickedFile = event.target.files && event.target.files[0];
    const MAX_FILE_SIZE_IN_MB = 0.78125; /// 800KB
    const FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
    if (!pickedFile.type.startsWith("image/")) {
      setMessageAlertError("Please choose a valid image file.");
      return;
    }
    if (pickedFile !== undefined) {
      setMessageAlertError("");
      /// Handles smaller image
      if (pickedFile.size <= FILE_SIZE_IN_BYTES) {
        props.handleFileUpload(pickedFile);
        return;
      }
      /// Handles larger image
      const options = {
        maxSizeMB: MAX_FILE_SIZE_IN_MB,
        useWebWorker: true,
        maxWidthOrHeight: 800,
      };
      try {
        const compressedFile = await imageCompression(pickedFile, options);
        props.handleFileUpload(compressedFile);
      } catch (error) {
        console.log("Error caught while compressing file:", error);
      }
    }
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px 29px !important" }}>
        <MDBox>
          {messageAlertError.isNotEmpty() && (
            <MessageAlert
              message={messageAlertError}
              type="error"
              icon={<InfoOutlined />}
            />
          )}
          <Grid container pb={3.5}>
            <Grid item xs={12} mb={1}>
              <MDTypography
                variant="bodyline2"
                fontWeight="bold"
                sx={{
                  fontSize: 14,
                }}
              >
                Logo
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox />
              <UserImageAvatar imageId={props.fileId} size="xl" shadow="sm" />
            </Grid>
            <Grid
              item
              xs={true}
              display="flex"
              pr="20px"
              justifyContent="end"
              alignItems="center"
            >
              <MDBox display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "secondary.light",
                    color: "primary.main",
                  }}
                  onClick={() => {
                    hiddenFileInputRef.current.click();
                  }}
                >
                  UPLOAD PICTURE
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenFileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <MDBox pt={1} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <MDBox flex={1}>
            <MDTypography sx={regularTextStyle}>Name <RequiredFieldMarker /></MDTypography>
            <MDInput
              value={name}
              placeholder="Enter Name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setName(value);
                setError(!value ? "This field cannot be empty" : "");
              }}
              fullWidth
            />
            <MDTypography
              sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
            >
              {error}
            </MDTypography>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <>
          <MDButton
            key={1}
            variant="outlined"
            color="dark"
            size="medium"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              borderColor: "#C7CCD0",
              padding: "11px 16px",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": { borderColor: "#C7CCD0" },
            }}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="medium"
            disabled={!name || props.name === name}
            onClick={() => {
              props.onSuccess({ name: name });
              props.closeDialog();
            }}
          >
            SAVE
          </MDButton>
        </>
      </DialogActions>
    </>
  );
};

export default EditClientProfileDialogContent;
