import { WebServiceStatus } from "@ivueit/vue-engine";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import { regularTextStyle } from "pages/surveys/styles/SurveyStyles";
import CompaniesList from "./CompaniesList";
import { mdiPlus } from "@mdi/js";
import { UsersGridContainer as usersGridContainer } from "../styles/UsersStyles";
import DataGridBottomBar from "pages/vuers/components/BottomBar";
import { useCallback, useEffect, useState } from "react";
import CustomDialogBox from "pages/components/CustomDialogBox";
import ConfirmReadOnlyDialog from "./ConfirmReadOnlyDialog";
import {
  addAdminRoleForNewCompany,
  getCompanyGroup,
  getOrgUsersByCompanyGroupId,
  updateCompanyGroupCompanies,
} from "../services/services";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { ClientSearchComponent } from "pages/surveys/components/ClientSearchComponent";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";

const CompaniesListTabContent = ({
  companyGroupId,
}: {
  companyGroupId: string;
}) => {
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [companyToAdd, setCompanyToAdd] = useState<Company>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] =
    useState<boolean>(false);

  const addNewCompany = async (isReadOnly: boolean) => {
    setShowLoader(true);
    const pageSize = 1;
    const pageNumber = 1000;
    const showDeleted = false;
    const parameterString = `pagination.pageNumber=${pageNumber}&pagination.pageSize=${pageSize}&showDeleted=${showDeleted}`;
    const orgUserResponse = await getOrgUsersByCompanyGroupId(
      companyGroupId,
      parameterString
    );
    if (orgUserResponse.status === WebServiceStatus.success) {
      const orgUsers = orgUserResponse.data.orgUsers as UserInfo[];
      const emailsList = orgUsers.map((user) => user.email);
      const newCompany = {
        id: companyToAdd.id,
        isReadOnly: isReadOnly,
      };
      const existingCompaniesList = companiesList.map((company) => {
        return {
          id: company.id,
          isReadOnly: company.isReadOnlyMemberOfGroup,
        };
      });
      const memberCompaniesList = [...existingCompaniesList, newCompany];
      const response = await updateCompanyGroupCompanies(
        companyGroupId,
        memberCompaniesList
      );
      if (response.status === WebServiceStatus.success) {
        if (emailsList.length > 0) {
          const response = await addAdminRoleForNewCompany(
            emailsList,
            companyToAdd.id,
            isReadOnly
          );
          if (response.status !== WebServiceStatus.success) {
            fetchCompanyGroups();
            setSnackBar({
              title: "Attention!",
              message: response.error,
              isError: true,
            });
            return;
          }
        }
        setSnackBar({
          title: "Success",
          message: "Company added successfully",
          isError: false,
        });
        await fetchCompanyGroups();
      } else {
        setSnackBar({
          title: "Attention!",
          message: response.error,
          isError: true,
        });
      }
    } else {
      setSnackBar({
        title: "Attention!",
        message: orgUserResponse.error,
        isError: true,
      });
    }
    setCompanyToAdd(null);
    setShowLoader(false);
    setOpenAddCompanyDialog(false);
  };

  const getConfirmReadOnlyDialog = () => {
    return (
      <CustomDialogBox
        title={"Add Company"}
        width="540px"
        openDialog={openAddCompanyDialog}
      >
        <ConfirmReadOnlyDialog
          companyName={companyToAdd && companyToAdd.name}
          closeDialog={() => {
            setOpenAddCompanyDialog(false);
          }}
          onAddCompany={(isReadOnly: boolean) => {
            addNewCompany(isReadOnly);
          }}
        />
      </CustomDialogBox>
    );
  };

  const fetchCompanyGroups = useCallback(async () => {
    setShowLoader(true);
    const response = await getCompanyGroup(companyGroupId);
    if (response.status === WebServiceStatus.success) {
      const data = response.data.companies as Company[];
      setCompaniesList(data);
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  }, []);

  useEffect(() => {
    fetchCompanyGroups();
  }, []);

  return (
    <>
      {showLoader && <CustomIndicator />}
      {openAddCompanyDialog && getConfirmReadOnlyDialog()}
      <MDBox px="16px" pt={4}>
        <MDBox mb={2}>
          <MDTypography sx={regularTextStyle}>Add Company</MDTypography>
          <MDBox display="flex">
            <MDBox display="flex" flexDirection="column" width="360px">
              <ClientSearchComponent
                company={companyToAdd}
                onSuccess={(company?: Company) => {
                  setCompanyToAdd(company);
                }}
              />
            </MDBox>
            <StyledButtonWithIcon
              sx={{ ml: "5px", minWidth: "unset", px: "11px", height: "40px" }}
              disabled={!companyToAdd}
              onClick={() => {
                setOpenAddCompanyDialog(true);
              }}
              iconPath={mdiPlus}
            />
          </MDBox>
        </MDBox>
        <MDBox sx={usersGridContainer}>
          {companiesList.length > 0 ? (
            <>
              <CompaniesList
                companiesList={companiesList}
                companyGroupId={companyGroupId}
                onRefresh={() => {
                  fetchCompanyGroups();
                }}
              />
              <DataGridBottomBar
                title={"Total Active Companies"}
                totalItems={companiesList.length}
                sx={{
                  position: "sticky",
                  bottom: "0",
                  zIndex: "125",
                  background: "white",
                  marginTop: "auto",
                }}
              />
            </>
          ) : (
            <MDBox m="auto">
              <PlaceholderComponent label="No companies available" />
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default CompaniesListTabContent;
