import { mdiFilterOff, mdiDelete } from "@mdi/js";
import { Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment, { Moment } from "moment";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import CustomTimePicker from "pages/dashboard/components/CustomTimePicker";
import { useEffect, useState } from "react";

const FilterByTimeContent: React.FC<{
  selectedStartTime: string;
  selectedEndTime: string;
  columnName: string;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  removeColumn: () => void;
  applyTimeFilter: (startTime: Moment | null, endTime: Moment | null) => void;
}> = (props) => {
  /// Default time format
  const timeFormat = "HH:mm a";
  const startTimeInMoment = moment(props.selectedStartTime, timeFormat);
  const endTimeInMoment = moment(props.selectedEndTime, timeFormat);

  /// Checks whether the dates are valid or not
  const isValidEndTime = props.selectedEndTime && endTimeInMoment.isValid();
  const isValidStartTime =
    props.selectedStartTime && startTimeInMoment.isValid();

  const defaultStartTime = isValidStartTime
    ? moment(startTimeInMoment, timeFormat)
    : null;
  const defaultEndTime = isValidEndTime
    ? moment(endTimeInMoment, timeFormat)
    : null;

  const [textChanged, setTextChanged] = useState<boolean>(false);
  /// This holds the start time
  const [startTime, setStartTime] = useState<Moment | null>(defaultStartTime);
  /// This holds the end time
  const [endTime, setEndTime] = useState<Moment | null>(defaultEndTime);
  /// To handle error
  const [error, setError] = useState({
    START_TIME: "",
    END_TIME: "",
  });
  /// Handles the open/close of the time picker
  const [openStartTimePicker, setOpenStartTimePicker] = useState(false);
  const [openEndTimePicker, setOpenEndTimePicker] = useState(false);
  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (textChanged) {
      if (startTime && endTime && startTime.isValid() && endTime.isValid()) {
        props.applyTimeFilter(startTime, endTime);
      } else if (startTime === null && endTime === null) {
        props.applyTimeFilter(null, null);
      }
    }
  }, [startTime, endTime]);

  /// Handles the change in start time
  const handleStartTimeChange = (newTime: Moment | null) => {
    const isBeforeEndTime = newTime.isSameOrBefore(endTime);
    setError((prev) => ({
      START_TIME:
        newTime && endTime
          ? !newTime.isValid()
            ? "Invalid start time."
            : !isBeforeEndTime
            ? "Start Time exceeds the End Time."
            : ""
          : endTime && !newTime
          ? "Start time must not be empty."
          : "",
      END_TIME: !newTime.isValid() && !endTime.isValid() ? "" : prev.END_TIME,
    }));
    setStartTime(newTime ? newTime : null);
    setTextChanged(true);
  };

  /// Handles the change in end date - Based on this the maximum start date is set
  const handleEndTimeChange = (newTime: Moment | null) => {
    const isAfterStartTime = newTime.isSameOrAfter(startTime);
    setError((prev) => ({
      END_TIME:
        newTime && startTime
          ? !newTime.isValid()
            ? "Invalid end time."
            : !isAfterStartTime
            ? "End Time precedes the Start Time."
            : ""
          : startTime && !newTime
          ? "End time must not be empty."
          : "",
      START_TIME:
        !newTime.isValid() && !startTime.isValid() ? "" : prev.START_TIME,
    }));
    setEndTime(newTime ? newTime : null);
    setTextChanged(true);
  };

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmation((prevState) => !prevState);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  /// Handles the onClick of "YES, REMOVE" button
  const removeColumn = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.removeColumn();
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.closePopper(event);
  };

  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={removeColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes, Remove
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Remove ${props.columnName}`}
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to remove the ${props.columnName} column from the table?`}
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  /// Handles the onClick of "Remove Column" button in popper
  const handleRemoveColumnClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    openConfirmation();
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <MDBox>
          <MDBox px={2.5} pt={2}>
            <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
              Filter By Time
            </MDTypography>
            <MDBox pt={2} pb={openStartTimePicker ? 42 : 2}>
              <CustomTimePicker
                minWidth={55}
                title={"Start Time"}
                errorMessage={error.START_TIME}
                defaultTime={defaultStartTime}
                onChange={handleStartTimeChange}
                openDialog={openStartTimePicker}
                shouldOpenDialog={() => {
                  setOpenStartTimePicker(!openStartTimePicker);
                }}
                shouldCloseDialog={() => {
                  setOpenStartTimePicker(false);
                }}
              />
            </MDBox>
            <MDBox pb={openEndTimePicker ? 40 : 0}>
              <CustomTimePicker
                minWidth={55}
                title={"End Time"}
                errorMessage={error.END_TIME}
                defaultTime={defaultEndTime}
                onChange={handleEndTimeChange}
                openDialog={openEndTimePicker}
                shouldOpenDialog={() => {
                  setOpenEndTimePicker(!openEndTimePicker);
                }}
                shouldCloseDialog={() => {
                  setOpenEndTimePicker(false);
                }}
              />
            </MDBox>
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              height: "1x",
              opacity: 0.5,
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={2} pb={2}>
            <MDBox>
              <ButtonWithIcon
                iconPath={mdiFilterOff}
                fullWidth
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1A73E7",
                  "&:disabled": { color: "#c7ccd0", borderColor: "#c7ccd0" },
                  "&:hover": { color: "#1A73E7", borderColor: "#c7ccd0" },
                }}
                onClick={(
                  event: Event | React.SyntheticEvent<Element, Event>
                ) => {
                  props.applyTimeFilter(null, null);
                  props.closePopper(event);
                }}
              >
                CLEAR FILTERS
              </ButtonWithIcon>
            </MDBox>
            <MDBox pt={1}>
              <ButtonWithIcon
                iconPath={mdiDelete}
                fullWidth
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#AE1709",
                  "&:hover": { color: "#AE1709", borderColor: "#c7ccd0" },
                }}
                onClick={handleRemoveColumnClick}
              >
                REMOVE COLUMN
              </ButtonWithIcon>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

export default FilterByTimeContent;
