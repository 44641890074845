import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SupportBg from "assets/images/invoices_and_support_background-2.webp";
import AnalyticsBg from "assets/images/invoices_and_support_accents.png";
import { SxProps } from "@mui/material";

const comingSoonStyles: SxProps = {
  borderTop: "2px solid #869494",
  borderBottom: "2px solid #869494",
  pt: 8,
  pb: 8,
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media(max-width: 39.28em)": {
    width: "90%",
  },
};

const comingSoonContainerStyle: SxProps = {
  maxWidth: 600,
  textAlign: "center",
  p: 4,
};

const comingSoonTitle: SxProps = {
  fontWeight: "bold",
  fontSize: "3.5em",
  "@media(max-width: 39.28em)": {
    fontSize: "2em",
  },
};
const comingSoonDescription: SxProps = {
  fontWeight: "600",
  fontSize: "1.5em",
  lineHeight: "1.5em",
  "@media(max-width: 39.28em)": {
    fontSize: "1em",
  },
};

const ComingSoon = () => {
  return (
    <MDBox
      sx={{
        background: `url(${SupportBg}) black`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "88vh",
        borderRadius: "1em",
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          backgroundColor: "black",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: "-0.3em",
          right: "3%",
          height: "22em",
          width: "14em",
          background: `url(${AnalyticsBg})`,
          backgroundRepeat: "no-repeat",
        },
      }}
    >
      <MDBox sx={comingSoonStyles}>
        <MDBox
          sx={comingSoonContainerStyle}
          borderRadius="lg"
          bgColor="#869494a8"
        >
          <MDTypography variant="h2" color="white" sx={comingSoonTitle}>
            Coming Soon
          </MDTypography>
          <MDTypography
            variant="body2"
            color="white"
            sx={comingSoonDescription}
          >
            We are currently working on this feature and it will launch soon!
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default ComingSoon