import { Tooltip, Chip, Typography, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import Link from "@mui/material/Link";
import { tooltipStyles } from "../components/VueActionBar";
import {
  linkElementStyle,
  noChipStyle,
  yesChipStyle,
} from "../../styles/VueStyles";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import MDBox from "components/MDBox";

/********************* CUSTOM CELL ***********************/

/// Generates custom cell
export const getCustomCell = (
  params: GridRenderCellParams,
  onClick?: (tag?: string) => void,
  onMouseOverChip?: () => void,
  reasonsList?: string[],
  shouldShowAssignButton?: boolean,
  onClickAssignButton?: () => void
) => {
  const { field, value } = params;
  switch (field) {
    case "surveyscore":
      return value > 0 ? `${value}%` : "";
    case "hasEscalated":
      const hasEscalated = value as boolean;
      return getEscalatedChip(
        hasEscalated,
        () => {
          onMouseOverChip();
        },
        reasonsList
      );
    case "urgent":
    case "canUseReferral":
      const propValue = value as boolean;
      return propValue ? (
        <Chip label="YES" sx={yesChipStyle} />
      ) : (
        <Chip label="NO" sx={noChipStyle} />
      );
    case "vueTags":
    case "photoTags":
      const tagsList = value as string[];
      return getTags(tagsList, onClick);
    case "vueId":
      return getLinkElement(`#${value as string}`, onClick);
    case "vueName":
      if ((value as string).toUpperCase() === "UNASSIGNED") {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              color: "dark.main",
              fontWeight: "400",
              lineHeight: "unset",
            }}
          >
            {value.toUpperCase()}
          </Typography>
        );
      }
      return getLinkElement(value as string, onClick);
    case "siteName":
      let childElement = <></>;
      if (
        (value as string).toUpperCase() === "UNASSIGNED" ||
        params.row.jobSiteId === ""
      ) {
        childElement = (
          <Typography
            sx={{
              fontSize: "14px",
              color: "dark.main",
              fontWeight: "400",
              lineHeight: "unset",
            }}
          >
            {value.toUpperCase()}
          </Typography>
        );
      } else {
        childElement = getLinkElement(value as string, onClick);
      }
      return (
        <MDBox
          display="flex"
          alignItems="center"
          sx={{
            ":not(:hover) .MuiIconButton-root": { display: "none" },
            a: { lineHeight: "unset" },
          }}
        >
          {childElement}
          {/* Should show only when hovering the button */}
          {shouldShowAssignButton && (
            <IconButton
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onClickAssignButton();
              }}
            >
              <Icon color="#1b66dc" path={mdiPencil} size={0.72} />
            </IconButton>
          )}
        </MDBox>
      );
    default:
      break;
  }
};

/// This returns the Chip component for the cell "Escalated"
const getEscalatedChip = (
  hasEscalated: boolean,
  onMouseOverChip: () => void,
  reasonsList: string[]
) => {
  return hasEscalated && reasonsList.length > 0 ? (
    <Tooltip
      title={reasonsList.map((reason: string, index: number) => (
        <b key={index}>
          {reason}
          <br />
        </b>
      ))}
      arrow
      placement="right"
      componentsProps={{
        tooltip: {
          sx: tooltipStyles,
        },
      }}
    >
      <span
        onMouseOver={() => {
          onMouseOverChip();
        }}
      >
        <Chip label="YES" sx={yesChipStyle} />
      </span>
    </Tooltip>
  ) : hasEscalated ? (
    <span
      onMouseOver={() => {
        onMouseOverChip();
      }}
    >
      <Chip label="YES" sx={yesChipStyle} />
    </span>
  ) : (
    <Chip label="NO" sx={noChipStyle} />
  );
};

/// This returns the Tag component for the cell "Tags"
const getTags = (tagsList: string[], onClick: (tags: string) => void) => {
  return (
    <MDBox
      className="MuiDataGrid-cellContent"
      display="flex"
      alignItems="center"
      minHeight="35px"
    >
      {tagsList.slice(0, 2).map((tag: string, index: number) => (
        <CustomBadge
          key={index}
          content={tag}
          max={parseInt(tag)}
          mr={2}
          onClick={(event) => {
            event.stopPropagation();
            onClick(tag);
          }}
        />
      ))}
      {tagsList.length > 2 && (
        <Tooltip
          title={tagsList.slice(2).map((tag: string, index: number) => {
            return (
              <span
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  onClick(tag);
                }}
                style={{ cursor: "pointer" }}
              >
                <b key={tag}>
                  {tag}
                  <br />
                </b>
              </span>
            );
          })}
          arrow
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: tooltipStyles,
            },
          }}
          disableHoverListener={false}
        >
          <span>
            <MDTypography
              sx={{
                pl: 0.8,
                fontSize: "14px",
                fontWeight: "regular",
              }}
            >
              +{tagsList.length - 2} more
            </MDTypography>
          </span>
        </Tooltip>
      )}
    </MDBox>
  );
};

/// This returns the link component for the cell "VueId/SiteName"
const getLinkElement = (value: string, onClick: () => void) => {
  return (
    <Link
      sx={{ ...linkElementStyle, cursor: "pointer" }}
      onClick={onClick}
      variant="button"
      color="primary"
      fontWeight="regular"
      underline="always"
      ml={1}
    >
      {value}
    </Link>
  );
};
