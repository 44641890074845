import { Icon } from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight, mdiClose } from "@mdi/js";
import rgba from "assets/theme/functions/rgba";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { KeyboardEvents } from "./MapFullViewDialogContent";
import { IconButton } from "@mui/material";
import {
  IndividualVue,
  PhotoFileInfo,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import { useVueProvider } from "../../context/VueProvider";
import VideoThumbnail from "./VideoThumbnail";

interface Props {
  photoFileInfo: PhotoFileInfo;
  onClose: () => void;
}

const navigationArrowStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "3",
  opacity: 0.75,
  filter: "drop-shadow(0px 0px 3px black)",
  color: "#FFFFFF",
  ":hover": {
    opacity: 1,
  },
};

export const ImagePopUp = ({ photoFileInfo, onClose }: Props) => {
  /// Context value
  const {
    individualVue,
  }: {
    individualVue: IndividualVue;
  } = useVueProvider();
  const { photos, videos } = individualVue;
  const availablePhotos = photos.filter((photo) => !photo.clientHide);
  const availableVideos = videos.filter((video) => !video.clientHide);
  const availableMedias = [...availablePhotos, ...availableVideos];
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>();
  const [photo, setPhoto] = useState<PhotoFileInfo>(photoFileInfo);
  const [video, setVideo] = useState<VideoFileInfo>(null);
  const lastPhotoIndex = availableMedias.length - 1;

  const handleSetMedia = (index: number) => {
    const media = availableMedias[index];
    const isVideo = media.mimeType === "video/mp4";
    if (isVideo) {
      const video = media as VideoFileInfo;
      setVideo(video);
      setPhoto(null);
    } else {
      const photo = media as PhotoFileInfo;
      setPhoto(photo);
      setVideo(null);
    }
  };

  const handleLeftArrowClick = () => {
    const index =
      currentPhotoIndex === 0 ? lastPhotoIndex : currentPhotoIndex - 1;
    setCurrentPhotoIndex(index);
    handleSetMedia(index);
  };

  const handleRightArrowClick = () => {
    const index =
      currentPhotoIndex === lastPhotoIndex ? 0 : currentPhotoIndex + 1;
    setCurrentPhotoIndex(index);
    handleSetMedia(index);
  };

  useEffect(() => {
    const index = availablePhotos.findIndex(
      (media) => photoFileInfo.id === media.id
    );
    setCurrentPhotoIndex(index);
  }, []);

  /// To handle keyboard events
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      let index = currentPhotoIndex;
      if (event.key === KeyboardEvents.arrowLeft) {
        index =
          currentPhotoIndex === 0 ? lastPhotoIndex : currentPhotoIndex - 1;
      } else if (event.key === KeyboardEvents.arrowRight) {
        index =
          currentPhotoIndex === lastPhotoIndex ? 0 : currentPhotoIndex + 1;
      }
      setCurrentPhotoIndex(index);
      handleSetMedia(index);
    };
    document.addEventListener(KeyboardEvents.keydown, handleKeyDown);
    return () => {
      document.removeEventListener(KeyboardEvents.keydown, handleKeyDown);
    };
  }, [currentPhotoIndex]);

  return (
    <MDBox
      role="presentation"
      sx={{
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "1340",
        display: "flex",
      }}
    >
      <MDBox
        aria-hidden="true"
        className="popUpBackdrop"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: "-1",
          background: rgba("black", 0.55),
        }}
      ></MDBox>
      <MDBox
        sx={{
          margin: "auto",
          position: "relative",
          minWidth: "300px",
          minHeight: "340px",
        }}
      >
        <MDButton
          variant="text"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            padding: "4px",
            minWidth: "auto",
            minHeight: "auto",
            color: "black",
            svg: {
              filter: "drop-shadow(0px 0px 2px rgb(0 0 0 / 0.65))",
            },
          }}
        >
          <Icon path={mdiClose} size={1.2} />
        </MDButton>
        {availableMedias.length > 1 && (
          <IconButton
            sx={{ ...navigationArrowStyle, left: 0 }}
            onClick={handleLeftArrowClick}
          >
            <Icon
              path={mdiChevronLeft}
              size={1.6}
              style={{ cursor: "pointer" }}
            />
          </IconButton>
        )}
        {photo ? (
          <img
            src={`data:${photo.mimeType};base64,${photo.data}`}
            alt=""
            style={{
              width: "auto",
              height: "auto",
              maxWidth: "90vw",
              maxHeight: "92vh",
              boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.85)",
            }}
          />
        ) : (
          <VideoThumbnail
            thumbnailFileId={video.thumbnailFileId}
            imageWidth={"100%"}
            imageHeight={"42vh"}
          />
        )}
        {availableMedias.length > 1 && <IconButton
          sx={{ ...navigationArrowStyle, right: 0 }}
          onClick={handleRightArrowClick}
        >
          <Icon
            path={mdiChevronRight}
            size={1.6}
            style={{ cursor: "pointer" }}
          />
        </IconButton>}
      </MDBox>
    </MDBox>
  );
};
