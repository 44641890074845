import { searchBarStyle } from "@ivueit/vue-engine";
import { mdiPlus } from "@mdi/js";
import { TextField } from "@mui/material";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { regularTextStyle } from "../styles/SurveyStyles";
import { useState } from "react";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  title?: string;
  required?: boolean;
  onAddOption: (newOptions: string[]) => void;
  optionsList: string[];
}

export const CustomAddoption = ({ onAddOption, optionsList, title, required }: Props) => {
  const [option, setOption] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [disableAddButton, setDisableAddButton] = useState<boolean>(false);

  const handleAddOption = () => {
    const newList = [...optionsList, option];
    onAddOption(newList);
    setOption("");
  };

  const onOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = event.target.value;
    const optionAlreadyExists = optionsList
      .map((option) => option.trim().toLowerCase())
      .includes(newOption.trim().toLowerCase());
    setError(optionAlreadyExists ? "Option already exists" : "");
    setDisableAddButton(optionAlreadyExists);
    setOption(newOption);
  };

  return (
    <>
      {title && 
      <MDTypography sx={regularTextStyle}>{title}{required && <RequiredFieldMarker />}</MDTypography>
      }
      <MDBox display="flex">
        <MDBox display="flex" flexDirection="column" mr={1}>
          <TextField
            fullWidth
            value={option}
            sx={searchBarStyle}
            placeholder=""
            InputLabelProps={{ shrink: true }}
            onChange={onOptionChange}
          />
          {error && (
            <MDTypography
              sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}
            >
              {error}
            </MDTypography>
          )}
        </MDBox>
        <StyledButtonWithIcon
          sx={{ ml: "5px", minWidth: "unset", px: "11px" }}
          onClick={handleAddOption}
          disabled={disableAddButton || !option.trim()}
          iconPath={mdiPlus}
        />
      </MDBox>
    </>
  );
};
