import { DialogActions, DialogContent } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { useState } from "react";
import { EscalationSummary } from "./EscalationSummary";
import {
  IndividualVue,
  PhotoFileKeys,
  PhotoFileInfo,
  VideoFileInfo,
} from "../utils/vue_detail_interface";
import { IndividualVueActionType } from "../utils/reducer";
import { REQUEST_TYPE } from "pages/profile/services/ProfileServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  regenerateReportsBySubmissionId,
  updatePhotoFileInfo,
  updateVideoFileInfo,
} from "../../services/VueServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { InfoOutlined } from "@mui/icons-material";
import { PlaceholderComponent } from "components/CustomComponents/PlaceholderComponent";
import { GENERIC_ERROR_MESSAGE } from "../../../../../../constants";

export const DeEscalateDialogContent: React.FC<{ closeDialog: () => void }> = (
  props
) => {
  const {
    individualVue,
    dispatchIndividualVueAction,
    storeLatestSelectedVueImage,
    latestSelectedVueImage,
  } = useVueProvider();

  const { vue, photos, submissionId } = individualVue as IndividualVue;

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  /// List of all photos that are escalated by client & admin
  const clientEscalatedPhotos = photos.filter(
    (photo) => photo.escalated && !photo.adminEscalated
  );
  const adminEscalatedPhotos = photos.filter(
    (photo) => photo.escalated && photo.adminEscalated
  );
  /// List of all videos that are escalated by client & admin
  const adminEscalatedVideos = (individualVue.videos as VideoFileInfo[]).filter(
    (video) => video.escalated && video.adminEscalated
  );
  const clientEscalatedVideos = (
    individualVue.videos as VideoFileInfo[]
  ).filter((video) => video.escalated && !video.adminEscalated);

  const escalatedPhotos = [...clientEscalatedPhotos, ...adminEscalatedPhotos];
  const escalatedVideos = [...clientEscalatedVideos, ...adminEscalatedVideos];

  /// List of all photos that are selected to be de-escalated
  const [selectedEscalatedPhotos, setSelectedEscalatedPhotos] = useState<
    PhotoFileInfo[]
  >([]);
  /// List of all videos that are selected to be de-escalated
  const [selectedEscalatedVideos, setSelectedEscalatedVideos] = useState<
    VideoFileInfo[]
  >([]);

  const updatePhotoFile = async (
    photo: PhotoFileInfo,
    request: REQUEST_TYPE,
    actionType: IndividualVueActionType
  ) => {
    setShowLoader(true);
    setIsLoading(true);
    const response = await updatePhotoFileInfo(photo.id, vue.id, request);
    if (response.status === WebServiceStatus.success) {
      const photoFile = {
        ...response.data,
        stepStateId: photo.stepStateId,
        data: photo.data,
        serialNumber: photo.serialNumber,
      } as PhotoFileInfo;
      dispatchIndividualVueAction({
        type: actionType,
        payload: {
          ...individualVue,
          photos: [photoFile],
        },
      });
      /// Updating the locally stored photo props
      if (latestSelectedVueImage) {
        const key = Object.keys(latestSelectedVueImage)[0];
        const value: PhotoFileInfo = latestSelectedVueImage[key];
        if (value && key === vue.canonicalId && value.id === photoFile.id) {
          storeLatestSelectedVueImage(vue.canonicalId, photoFile);
        }
      }
      await regeneratePDFReports();
    } else {
      setError(response.error);
    }
    setIsLoading(false);
    setShowLoader(false);
  };

  const updateVideoFile = async (
    video: VideoFileInfo,
    request: REQUEST_TYPE,
    actionType: IndividualVueActionType
  ) => {
    setIsLoading(true);
    setShowLoader(true);
    const response = await updateVideoFileInfo(video.id, vue.id, request);
    if (response.status === WebServiceStatus.success) {
      const videoFile = {
        ...response.data,
        stepStateId: video.stepStateId,
        serialNumber: video.serialNumber,
      } as VideoFileInfo;
      dispatchIndividualVueAction({
        isStepTypeVideo: true,
        type: actionType,
        payload: {
          ...individualVue,
          videos: [videoFile],
        },
      });
      await regeneratePDFReports();
    } else {
      setError(response.error);
    }
    setIsLoading(false);
    setShowLoader(false);
  };

  // Regenerates the PDF reports on de escalation
  const regeneratePDFReports = async () => {
    setIsLoading(true);
    setShowLoader(true);
    setIsLoading(true);
    const response = await regenerateReportsBySubmissionId(submissionId);
    if (response.status === WebServiceStatus.success) {
      setError("");
      props.closeDialog();
    } else {
      setError(response.error);
    }
    setIsLoading(false);
    setShowLoader(false);
  };

  const handleApplyAllButtonClick = () => {
    const param = { [PhotoFileKeys.escalated]: { value: false } };
    if (escalatedPhotos.length > 0) {
      if (escalatedPhotos.length === selectedEscalatedPhotos.length) {
        escalatedPhotos.map((photo) =>
          updatePhotoFile(
            photo,
            param,
            IndividualVueActionType.editMediaEscalation
          )
        );
        dispatchIndividualVueAction({
          type: IndividualVueActionType.editVueEscalation,
          payload: {
            ...individualVue,
            vue: {
              ...individualVue.vue,
              escalated: false,
            },
          },
        });
      } else {
        escalatedPhotos.map((photo) =>
          updatePhotoFile(
            photo,
            param,
            IndividualVueActionType.editMediaEscalation
          )
        );
      }
    }
    if (escalatedVideos.length > 0) {
      if (escalatedVideos.length === selectedEscalatedVideos.length) {
        escalatedVideos.map((video) =>
          updateVideoFile(
            video,
            param,
            IndividualVueActionType.editMediaEscalation
          )
        );
        dispatchIndividualVueAction({
          type: IndividualVueActionType.editVueEscalation,
          payload: {
            ...individualVue,
            vue: {
              ...individualVue.vue,
              escalated: false,
            },
          },
        });
      } else {
        escalatedVideos.map((video) =>
          updateVideoFile(
            video,
            param,
            IndividualVueActionType.editMediaEscalation
          )
        );
      }
    }
  };

  const handleDeEscalateMediaButtonClick = (
    shouldDeEscalate: boolean,
    photo?: PhotoFileInfo,
    video?: VideoFileInfo
  ) => {
    if (photo) {
      const photosList = shouldDeEscalate
        ? [...selectedEscalatedPhotos, photo]
        : selectedEscalatedPhotos.filter((item) => item.id !== photo.id);
      setSelectedEscalatedPhotos(photosList);
    }
    if (video) {
      const videosList = shouldDeEscalate
        ? [...selectedEscalatedVideos, video]
        : selectedEscalatedVideos.filter((item) => item.id !== video.id);
      setSelectedEscalatedVideos(videosList);
    }
  };

  const getEscalatedMediaList = (
    title: string,
    escalatedPhotosList: PhotoFileInfo[],
    escalatedVideosList: VideoFileInfo[]
  ) => {
    return (
      <>
        <MDTypography
          fontSize="16px"
          pt="10px"
          px="4px"
          mb="12px"
          sx={{ fontWeight: "600" }}
        >
          {title}
        </MDTypography>
        {escalatedPhotosList.length > 0 &&
          escalatedPhotosList.map((escalatedPhoto, index) => (
            <MDBox
              key={index}
              py="15px"
              sx={{ borderBottom: "1px solid #DEE2E8" }}
            >
              <EscalationSummary
                escalatedPhoto={escalatedPhoto}
                deEscalateButtonClick={(shouldDeEscalate: boolean) => {
                  handleDeEscalateMediaButtonClick(
                    shouldDeEscalate,
                    escalatedPhoto,
                    null
                  );
                }}
                showDeEscalateButton
              />
            </MDBox>
          ))}
        {escalatedVideosList.length > 0 &&
          escalatedVideosList.map((escalatedVideo, index) => (
            <MDBox
              key={index}
              py="15px"
              sx={{ borderBottom: "1px solid #DEE2E8" }}
            >
              <EscalationSummary
                showDeEscalateButton
                isStepTypeVideo={true}
                escalatedVideo={escalatedVideo}
                deEscalateButtonClick={(shouldDeEscalate: boolean) => {
                  handleDeEscalateMediaButtonClick(
                    shouldDeEscalate,
                    null,
                    escalatedVideo
                  );
                }}
              />
            </MDBox>
          ))}
      </>
    );
  };

  const getDialogContent = () => {
    let elements: JSX.Element[] = [];
    if (clientEscalatedPhotos.length > 0 || clientEscalatedVideos.length > 0) {
      const clientEscalatedMedias = getEscalatedMediaList(
        "Client Escalated Photos",
        clientEscalatedPhotos.length > 0 ? clientEscalatedPhotos : [],
        clientEscalatedVideos.length > 0 ? clientEscalatedVideos : []
      );
      elements.push(clientEscalatedMedias);
    }
    if (adminEscalatedPhotos.length > 0 || adminEscalatedVideos.length > 0) {
      const adminEscalatedMedias = getEscalatedMediaList(
        "iVueit Escalated Photos",
        adminEscalatedPhotos.length > 0 ? adminEscalatedPhotos : [],
        adminEscalatedVideos.length > 0 ? adminEscalatedVideos : []
      );
      elements.push(adminEscalatedMedias);
    }
    return elements;
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DialogContent
        sx={{
          ".MuiBox-root:last-child": {
            borderBottom: "none",
          },
          minHeight: "150px",
        }}
      >
        {error && (
          <MessageAlert message={error} type="error" icon={<InfoOutlined />} />
        )}
        {escalatedPhotos.length > 0 || escalatedVideos.length > 0 ? (
          getDialogContent()
        ) : (
          <MDBox mt={5}>
            <PlaceholderComponent
              label={isLoading ? "" : "No medias available"}
            />
          </MDBox>
        )}
      </DialogContent>
      {/* Action buttons */}
      <DialogActions
        sx={{ borderTop: "1px solid #DEE2E8", paddingTop: "25px" }}
      >
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          onClick={() => {
            props.closeDialog();
          }}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          disabled={
            selectedEscalatedPhotos.length === 0 &&
            selectedEscalatedVideos.length === 0
          }
          sx={{
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={handleApplyAllButtonClick}
        >
          APPLY ALL
        </MDButton>
      </DialogActions>
    </>
  );
};
