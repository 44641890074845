import {
  Company,
  CustomIndicator,
  SurveyStep,
  SurveyStepType,
  SurveySummary,
  VueSurvey,
  WebServiceStatus,
  getBodyContent,
  getHeaderContent,
} from "@ivueit/vue-engine";
import { Card, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { getCompanyDetailsById } from "pages/dashboard/home/common/services/services";
import { GroupedQAComponent } from "pages/dashboard/home/vues/vue_detail/components/GroupedQAComponent";
import VueDescription from "pages/dashboard/home/vues/vue_detail/components/VueDescription";
import { VueSurveyData } from "pages/dashboard/home/vues/vue_detail/utils/vue_detail_interface";
import { useEffect, useState } from "react";
import {
  cloneSurveyTemplate,
  updateSurveyTemplate,
} from "../services/SurveyServices";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { GENERIC_ERROR_MESSAGE } from "../../../constants";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

interface Props {
  survey: VueSurveyData;
}

const PublishedSurvey = (props: Props) => {
  const { survey, groups } = props.survey;
  const [selectedSurvey, setSelectedSurvey] = useState<VueSurvey>(survey);
  const [showLoader, setShowLoader] = useState(false);
  const [arRequired, setArRequired] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  const {
    name,
    canonicalId,
    autoSlaHours,
    autoCostPerVue,
    autoPayoutPerVue,
    autoUrgentCost,
    autoUrgentPayout,
    internalNetworkPayout,
    typeOf,
    steps,
    archived,
  } = selectedSurvey;

  // Uses to get the company details
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const portalId = props.survey.survey.companyId?.value;
      if (portalId) {
        setShowLoader(true);
        if (portalId.isEmpty()) {
          setShowLoader(false);
          return;
        }
        const response = await getCompanyDetailsById(portalId);
        if (response.status === WebServiceStatus.success) {
          const companyDetails = response.data as Company;
          setCompany(companyDetails);
        } else {
          console.log("Error: Unable to display the preview");
        }
        setShowLoader(false);
      }
    };

    fetchCompanyDetails();
  }, [props.survey.survey.companyId]);

  useEffect(() => {
    if (steps) {
      const requiresAR = steps.some(
        (step) => step.stepType.toUpperCase() === SurveyStepType.measurement
      );
      setArRequired(requiresAR);
    }
  }, [steps]);

  const onArchiveButtonClick = async () => {
    setShowLoader(true);
    const updatedSurvey = { ...survey, archived: !survey.archived };
    const response = await updateSurveyTemplate(
      updatedSurvey,
      updatedSurvey.id
    );
    if (response.status === WebServiceStatus.success) {
      const newSurvey = response.data as VueSurvey;
      setSelectedSurvey(newSurvey);
      setSnackbarContent({
        title: "Success!",
        message: `Archive status has been updated.`,
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onCloneButtonClick = async () => {
    const surveyId = survey?.id;
    setShowLoader(true);
    if (!surveyId) {
      setShowLoader(false);
      return;
    }
    const response = await cloneSurveyTemplate(surveyId);
    if (response.status === WebServiceStatus.success) {
      const newSurvey = response.data as VueSurvey;
      setSnackbarContent({
        title: "Success!",
        message: `New survey #${newSurvey.canonicalId} has been created from this template.`,
        isError: false,
      });
      // TODO: Check whether we need to navigate to the details page
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  // Generates the survey contents based on the steps and groups
  const getSurveyContents = () => {
    const allSteps: SurveyStep[] = steps ?? [];

    if (allSteps.length <= 0) {
      return <></>;
    }

    var elementArray: JSX.Element[] = [];
    // Contains the group Ids for which the elements have already generated
    var existingGroups: string[] = [];
    allSteps.forEach((step, stepIndex) => {
      if (step.groupId.isEmpty()) {
        // Step is NOT part of any groups
        const nonGroupElement = (
          <SurveySummary
            key={stepIndex}
            header={`${stepIndex + 1}. ${getHeaderContent(step.stepType)}`}
            body={getBodyContent(step)}
          />
        );
        elementArray.push(nonGroupElement);
      } else {
        // Finding group that contins current step
        const group = groups.find((item) => item.id === step.groupId);
        const stepsWithGroupId = allSteps.filter(
          (groupStep) => groupStep.groupId === step.groupId
        );
        // Ensures that the group is NOT already rendered
        if (group && !existingGroups.includes(group.id)) {
          var groupElements: JSX.Element[] = [];
          // Traversing through each instance to display them as group
          stepsWithGroupId.forEach((stepInstance, groupStepIndex) => {
            const stepNumber = stepIndex + groupStepIndex + 1;
            const children = (
              <SurveySummary
                key={stepNumber}
                header={`${stepNumber}. ${getHeaderContent(
                  stepInstance.stepType
                )}`}
                body={getBodyContent(stepInstance)}
              />
            );
            groupElements.push(children);
          });
          const groupedElement = (
            <GroupedQAComponent
              key={`${group.id}-${stepIndex}`}
              groupName={group.groupTitle}
              element={groupElements}
            />
          );
          elementArray.push(groupedElement);
          // Adds the current group id to the array, for filtering
          existingGroups.push(group.id);
        }
      }
    });
    return (
      <MDBox sx={{ pt: "10px", pb: "20px", px: "20px" }}>{elementArray}</MDBox>
    );
  };

  return (
    <MDBox>
      {showLoader && <CustomIndicator />}
      <MDBox>
        <MDBox
          display="flex"
          position="sticky"
          top="0px"
          zIndex="132"
          sx={{ backgroundColor: "#f0f2f5" }}
        >
          <MDBox flexGrow="1" pl={2} pb={1.4}>
            <MDBox display="flex" alignItems="center" mb={0.8}>
              <MDTypography variant="h5" color="black">
                {name}
              </MDTypography>
            </MDBox>
            {company && company.name.isNotEmpty() && (
              <MDBox display="flex" alignItems="center" mb={0.6}>
                <MDTypography variant="h6" color="#344767">
                  Company Name:
                </MDTypography>
                <MDTypography
                  variant="h6"
                  color="#344767"
                  pl={1}
                  sx={{ fontWeight: "500" }}
                >
                  {company.name}
                </MDTypography>
              </MDBox>
            )}
            {arRequired && (
              <MDBox display="flex" mb={0.6}>
                <CustomBadge
                  content={"AR"}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white !important",
                      background: "#344767",
                      textTransform: "unset",
                    },
                  }}
                />
              </MDBox>
            )}
          </MDBox>
          <MDBox
            flexShrink="0"
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <MDBox display="flex">
              <MDButton
                variant="contained"
                color="info"
                onClick={onArchiveButtonClick}
                sx={{ fontSize: "14px", fontWeight: "bold" }}
              >
                {archived ? "UNARCHIVE" : "ARCHIVE"}
              </MDButton>
              <MDBox ml={1}>
                <MDButton
                  variant="contained"
                  color="info"
                  onClick={onCloneButtonClick}
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  CLONE
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="left" pb={1.4}>
          <Card sx={{ width: "100%" }}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="start"
              flexWrap="wrap"
              px={2}
              py={2}
            >
              <VueDescription title="ID" value={`#${canonicalId}`} />
              <VueDescription
                title="AUTO SLA HOURS"
                value={`${autoSlaHours?.value ?? 0}`}
              />
              <VueDescription
                title="AUTO COST PER VUE"
                value={`$${autoCostPerVue?.value ?? 0}`}
              />
              <VueDescription
                title="AUTO PAYOUT PER VUE"
                value={`$${autoPayoutPerVue?.value ?? 0}`}
              />
              <VueDescription
                title="VUE URGENT COST"
                value={`$${autoUrgentCost?.value ?? 0}`}
              />
              <VueDescription
                title="VUE URGENT PAYOUT"
                value={`$${autoUrgentPayout?.value ?? 0}`}
              />
              <VueDescription
                title="INTERNAL NETWORK PAYOUT"
                value={`$${internalNetworkPayout ?? 0}`}
              />
              <VueDescription title="TYPE" value={typeOf} />
            </MDBox>
          </Card>
        </MDBox>
        <Card sx={{ mt: "20px" }}>
          <MDBox>
            {survey && (
              <MDBox sx={{ pt: "20px", px: "20px" }}>
                <MDTypography variant="h5" sx={{ fontSize: "18px", pb: "8px" }}>
                  Vue Overview:&nbsp;
                  <span style={{ fontWeight: "400" }}>
                    <ReactMarkdown linkTarget="_blank">
                      {survey.description}
                    </ReactMarkdown>
                  </span>
                </MDTypography>
              </MDBox>
            )}
            <Divider
              sx={{ backgroundColor: "#c7ccd0", height: "1px", borderWidth: 1 }}
            />
            {getSurveyContents()}
          </MDBox>
        </Card>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </MDBox>
  );
};

export default PublishedSurvey;
