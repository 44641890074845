import axios, { AxiosError } from "axios";
import { GENERIC_ERROR_MESSAGE } from "../../../constants";
import { LocalStorageKeys } from "context/AuthProvider";
import {
  WebServiceResponse,
  WebServiceStatus,
  LoginAPIServices,
} from "utils/services/AppUrls";
import { performPostRequest } from "utils/services/NetworkHandler";

export const getAuthenticationHeader = () => {
  try {
    var header: { [key: string]: any } = {
      "Content-Type": "application/json",
    };
    const storedAuthToken: string = window.localStorage.getItem(
      LocalStorageKeys.authToken
    );
    var authToken: string | null = storedAuthToken.replace(/"/g, "");
    if (authToken != null && authToken.isNotEmpty()) {
      header = { x_ivueit_auth_token: authToken, ...header };
    }
    return header;
  } catch (error) {
    console.log("Error in the header: " + error);
  }
};

export const refreshAuthToken = async (): Promise<boolean> => {
  const storedAuthToken: string = window.localStorage.getItem(
    LocalStorageKeys.authToken
  );
  var authToken: string | null = storedAuthToken.replace(/"/g, "");
  if (authToken === null) {
    return;
  }
  const response = await refreshToken(authToken);
  if (response.status === WebServiceStatus.success) {
    const newAuthToken = response.data.body as string;
    window.localStorage.setItem(LocalStorageKeys.authToken, newAuthToken);
    return true;
  } else {
    return false;
  }
};

/// Handles the login functionality - validates the given email and password
export const performLogin = async (userCredentials: {
  email: string;
  password: string;
}): Promise<WebServiceResponse> => {
  return await performPostRequest(
    LoginAPIServices.validateEmailAndPassword,
    userCredentials
  );
};

/// Handles the password reset - sends email for resetting the password
export const requestEmailToResetPassword = async (
  email: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(LoginAPIServices.sendEmailToResetPassword, {
    email: email,
  });
};

/// This changes the password
export const handleChangePassword = async (setPasswordParameters: {
  email: string;
  password: string;
  requestId: string;
  verificationCode: string;
}): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.setPassword,
      data: setPasswordParameters,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: "Could not change your password.",
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 13
            ? responseData.message ??
              "Could not set the password. Please contact the admin team."
            : "Could not set the password. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This requests for SMS Pincode
export const requestSMSPincode = async (
  phoneNumber: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.requestSmsPincode,
      data: {
        phoneNumber: phoneNumber,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.recipient) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.auditId,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 2
            ? "You have entered an invalid phone number."
            : "Could not send the SMS. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This requests for SMS Pincode with email
export const requestSMSPincodeWithEmail = async (
  emailId: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.requestSMSPinCodeViaEmail,
      data: {
        email: emailId,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.recipient) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 7
            ? responseData.message ??
              "Could not send the code. Please contact the admin team."
            : "Could not send the code. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This requests for SMS Pincode with email
export const requestEmailPincode = async (
  emailId: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.requestEmailPincode,
      data: {
        email: emailId,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.recipient) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 7
            ? responseData.message ??
              "Could not send the SMS. Please contact the admin team."
            : "Could not send the SMS. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This verifies the given code received via mobile
export const verifySMSPincodeAndPhone = async (userDetails: {
  phoneNumber: string;
  email: string;
  verificationCode: string;
}): Promise<WebServiceResponse> => {
  return await performPostRequest(
    LoginAPIServices.verifySMSPinAndSavePhoneNumber,
    userDetails
  );
};

/// This verifies the given code received via email
export const verifyEmailPincode = async (userDetails: {
  requestId: string;
  email: string;
  verificationCode: string;
}): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.verifyEmailPincode,
      data: userDetails,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.success) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: "Could not verify the email pincode.",
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: "Could not verify the email pincode.",
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 5
            ? responseData.message ??
              "Could not verify the pincode. Please contact the admin team."
            : "Could not verify the pincode. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This return the auth token
export const getAuthTokenForClientPortal = async (authTokenParams: {
  email: string;
  requestId: string;
  verificationCode: string;
}): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.getAuthTokenForClientPortal,
      data: authTokenParams,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.body) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.body,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: "Could not get the auth token.",
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 13
            ? responseData.message ??
              "Invalid verification code. Please contact our support team for assistance."
            : "Invalid verification code. Please contact our support team for assistance.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This checks whether the token is valid
export const isTokenValid = async (
  token: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.isValidToken,
      data: {
        body: token,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.value) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.value,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 8
            ? responseData.message ?? "Failed to authenticate."
            : "Could not process the request. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This refreshes the token
export const refreshToken = async (
  token: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: LoginAPIServices.refreshToken,
      data: {
        body: token,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:
          responseData.code === 2
            ? responseData.message ?? "Failed to authenticate."
            : "Could not process the request. Please contact the admin team.",
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

