export const regularTextStyle = { fontSize: "14px", fontWeight: 500 };
export const gridTextStyle = { fontSize: "14px", fontWeight: 400 };
export const labelStyle = { fontSize: "14px", fontWeight: 500 };

export const iconButtonStyle = {
  marginRight: "16px",
  marginBottom: "16px",
};

export const dialogText = {
  fontSize: "14px",
  display: "block",
};

export const inputNumberStyle = {
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
};

export const formControlStyles = {
  display: "flex",
  flexDirection: "column",
  ".MuiFormControl-root": {
    marginLeft: "0",
    marginRight: "15px",
  },
};

export const helperTextStyle = {
  "p.MuiFormHelperText-root": {
    position: "absolute",
    bottom: "-16px",
    left: "5px",
    color: "#AE1709",
    fontWeight: 400,
    ml: 0,
  },
};
export const customHelperTextStyle = {
  "p.MuiFormHelperText-root": {
    position: "absolute",
    top: "100%",
    left: "5px",
    color: "#AE1709",
    fontWeight: 400,
    ml: 0,
  },
};
export const customListsyle = {
  minWidth: "0",
  ".MuiListItem-root:not(:first-child)": {
    borderTop: "1px solid whitesmoke",
  },

  ".MuiListItemIcon-root": {
    minWidth: "20px",
    fontSize: "1rem",
  },
  ".MuiTypography-root": {
    fontSize: "1rem",
  },
  ".MuiListItemButton-root": {
    alignItems: "start",
  },
  ".MuiListItemText-primary": {
    fontWeight: "500",
  },
  ".MuiListItemText-secondary": {
    fontSize: "0.85rem",
    fontWeight: "400",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export const listGroup = {
  marginBottom: "20px",
  borderRadius: "4px",
  ".MuiListItemButton-root": {
    paddingLeft: "30px",
    alignItems: "start",
  },
};
export const groupTitleStyle = {
  display: "flex",
  padding: "10px 15px !important",
  fontWeight: "700",
};

export const staticSideNav = {
  m: "20px",
  border: "1px solid #C7CCD0",
  borderRadius: "5px",
  position: "sticky",
  top: "110px",
  maxHeight: "calc(100vh - 220px)",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#9F9FA1",
    borderRadius: "3px",
    ":hover": {
      background: "#AF9FA1",
    },
  },
};

export const buttonOutlineStyles = {
  minHeight: "unset",
  fontSize: "14px",
  whiteSpace: "nowrap",
  borderColor: "#cccccc",
  "+ .MuiButton-root": {
    marginLeft: "15px",
  },
};

export const scrollBarStyle = {
  "::-webkit-scrollbar": {
    width: "8px",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#9F9FA1",
    borderRadius: "3px",
    ":hover": {
      background: "#AF9FA1",
    },
  },
};

export const tagItemStyle = {
  display: "flex",
  alignItems: "center",
  flexBasis: "100%",
  flexShrink: "0",
  padding: "0 20px 0 0",
  ":nth-child(3n + 1), :nth-child(3n + 0)": {
    background: "#F8F8F8",
  },
  ".MuiBadge-badge": {
    fontSize: "12px",
    fontWeight: "500"
  },
};
export const topBarButtonStyle = {
  borderColor: "secondary.light",
  color: "dark.main",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "white.main",
    borderColor: "secondary.light",
    color: "dark.main",
  },
  "&:focus": { color: "dark.main" },
};

export const textAreaStyle = {
  borderRadius: "5px",
  padding: "8px",
  width: "100%",
};

export const tableConStyle = {
  display: "flex",
  flexFlow: "column nowrap",
  backgroundCcolor: "white",
};

export const tableRowHeadStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  width: "100%",
  borderBottom: "1px solid #a8b8d8",
  textTransform: "uppercase",
  "p.MuiTypography-root": {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
};

export const tableHeadCellStyle = {
  display: "flex",
  flex: "1",
  py: "12px",
  justifyContent: "start",
  alignItems: "center",
  "&:first-child": {
    paddingLeft: "16px",
  },
};

export const tableRowStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  width: "100%",
  borderBottom: "1px solid #e9eaed",
  textTransform: "uppercase",
  "p.MuiTypography-root": {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
};

export const tableCellStyle = {
  display: "flex",
  flex: "1",
  py: "12px",
  justifyContent: "start",
  alignItems: "center",
  "&:first-child": {
    paddingLeft: "16px",
  },
  "p.MuiTypography-root": {
    fontSize: "16px !important",
  },
  ".MuiFormControl-root": {
    marginLeft: "0",
  },
};

export const archivedStyle = {
  "> .MuiBox-root:not(.tableCellActions)": {
    opacity: "0.3",
  },
};

export const surveysGridCardStyle = {
  minHeight: `calc(100vh - 162px)`,
  height: `calc(100vh - 162px)`,
  justifyContent: "flex-start",
  position: "relative",
  padding: "0 0 15px 0",
  overflowY: "auto",
};

export const tableStickyHead = {
  position: "sticky",
  top: "0",
  zIndex: "124",
  background: "white",
};
