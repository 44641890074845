import MDBox from "components/MDBox";
import { mdiAlertCircleOutline } from "@mdi/js";
import { IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { buttonHoverStyle } from "../styles/VuersStyles";
import { useState } from "react";
import CustomDialogBox from "pages/components/CustomDialogBox";
import MDButton from "components/MDButton";
import UserImageAvatar from "./UserImageAvatar";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { removeIllicitVuerProfilePhoto } from "../services/VuerServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { VuerData } from "@ivueit/vue-engine";

const VuersAvatar = ({
  vuer,
  blacklistUser,
}: {
  vuer: VuerData;
  blacklistUser: () => void;
}) => {
  const { id, profilePhotoFileId, phoneNumber: vuerPhoneNumber } = vuer;
  const [openProfilePicAlertDialog, setOpenProfilePicAlertDialog] =
    useState(false);
  const [profileID, setProfileID] = useState<string>(profilePhotoFileId);
  const [snackBar, setSnackBar] = useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const getProfilePicAlertDialog = () => {
    const actionButtons = [
      <>
        <MDButton
          key={1}
          variant="outlined"
          color="dark"
          size="medium"
          onClick={() => {
            setOpenProfilePicAlertDialog(false);
          }}
          sx={{
            borderColor: "#C7CCD0",
            padding: "11px 16px",
            fontSize: "14px",
            fontWeight: "bold",
            "&:hover": { borderColor: "#C7CCD0" },
          }}
        >
          CLOSE
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          sx={{ fontSize: "14px" }}
          onClick={removeIllicitProfilePic}
        >
          REMOVE
        </MDButton>
      </>,
    ];
    return (
      <CustomDialogBox
        title={"REMOVE PROFILE PIC"}
        width="454px"
        openDialog={openProfilePicAlertDialog}
      >
        <ModalPopUp
          content={
            "Remove this profile photo for being illicit? This action cannot be undone."
          }
          actions={actionButtons}
        />
      </CustomDialogBox>
    );
  };

  const removeIllicitProfilePic = async () => {
    if (!profileID) return;
    setShowLoader(true);
    const response = await removeIllicitVuerProfilePhoto(id);
    if (response.status === WebServiceStatus.success) {
      const data = response.data;
      const {
        shouldBlacklistThisNumber,
        phoneNumber,
      }: { shouldBlacklistThisNumber: boolean; phoneNumber: string } = data;
      setProfileID("");
      if (shouldBlacklistThisNumber && vuerPhoneNumber === phoneNumber) {
        blacklistUser();
      }
      setOpenProfilePicAlertDialog(false);
    } else {
      setSnackBar({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  return (
    <>
      {openProfilePicAlertDialog && getProfilePicAlertDialog()}
      {showLoader && <CustomIndicator />}
      <MDBox position="relative">
        <UserImageAvatar imageId={profileID} size="xl" shadow="sm" />
        {profileID && (
          <MDBox sx={buttonHoverStyle}>
            <IconButton
              color="primary"
              onClick={() => {
                setOpenProfilePicAlertDialog(true);
              }}
            >
              <Icon path={mdiAlertCircleOutline} size={1} />
            </IconButton>
          </MDBox>
        )}
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackBar}
        onClose={() => {
          setSnackBar(null);
        }}
      />
    </>
  );
};

export default VuersAvatar;
