import { mdiFilterOff, mdiDelete } from "@mdi/js";
import { Divider, List } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { SyntheticEvent, useState } from "react";
import { listStyle } from "./CustomSearchBarWithList";
import CustomRadioComponent from "pages/dashboard/components/CustomRadioComponent";

const SingleSelectionListFilter: React.FC<{
  previousSelectedOption: string;
  selectionList: string[];
  columnName: string;
  onSelectionChange: (selectedFilters: string[]) => void;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  removeColumn: () => void;
  shouldShowRemoveColumnButton: boolean;
}> = (props) => {
  const [selectedOption, setSelectedOption] = useState(
    props.previousSelectedOption
  );
  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmation(true);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  /// Handles the onClick of "YES, REMOVE" button
  const removeColumn = (event: Event | SyntheticEvent<Element, Event>) => {
    closeConfirmation();
    props.removeColumn();
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (event: Event | SyntheticEvent<Element, Event>) => {
    closeConfirmation();
    props.closePopper(event);
  };

  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={removeColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes, Remove
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Remove ${props.columnName}`}
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to remove the ${props.columnName} column from the table?`}
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  /// Handles the change in the check box
  const handleRadioSelectionChange = (option: string) => {
    setSelectedOption(option);
    props.onSelectionChange([option]);
  };

  /// Handles the onClick of "Remove Column" button in popper
  const handleRemoveColumnClick = (
    event: Event | SyntheticEvent<Element, Event>
  ) => {
    openConfirmation();
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <>
          <MDBox px={2} pt={3}>
            <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
              Filter By:
            </MDTypography>
            <List sx={listStyle}>
              {props.selectionList.map((label: string, index: number) => {
                return (
                  <CustomRadioComponent
                    key={index}
                    label={label}
                    checked={
                      label.toLowerCase() === selectedOption.toLowerCase()
                    }
                    handleChange={() => {
                      handleRadioSelectionChange(label);
                    }}
                  />
                );
              })}
            </List>
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              opacity: 0.5,
              height: "1x",
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={3} pb={2}>
            <MDBox>
              <ButtonWithIcon
                iconPath={mdiFilterOff}
                fullWidth
                disabled={selectedOption.isEmpty()}
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1A73E7",
                  "&:disabled": {
                    color: "#C7CCD0",
                    borderColor: "#C7CCD0",
                    opacity: 3,
                  },
                  "&:hover": { color: "#1A73E7", borderColor: "#C7CCD0" },
                }}
                onClick={(
                  event: Event | React.SyntheticEvent<Element, Event>
                ) => {
                  setSelectedOption("");
                  props.onSelectionChange([]);
                  props.closePopper(event);
                }}
              >
                CLEAR FILTERS
              </ButtonWithIcon>
            </MDBox>
            {props.shouldShowRemoveColumnButton && (
              <MDBox pt={1}>
                <ButtonWithIcon
                  iconPath={mdiDelete}
                  fullWidth
                  onClick={handleRemoveColumnClick}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#AE1709",
                    "&:hover": { color: "#AE1709", borderColor: "#C7CCD0" },
                  }}
                >
                  REMOVE COLUMN
                </ButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
        </>
      )}
    </>
  );
};

export default SingleSelectionListFilter;
